import * as React from "react";
import "./Button.css";
import CircularLoader from "../CircularLoader/CircularLoader"


var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Button = function Button(props) {
  var type = props.type,
      text = props.text,
      loading = props.loading,
      rest = _objectWithoutProperties(props, ["type", "text", "loading"]);

  return React.createElement(
    React.Fragment,
    null,
    React.createElement(
      "button",
      _extends({
        type: "button",
        className: type
      }, rest),
      loading && React.createElement(CircularLoader, { className: "loader-sm" }),
      text
    )
  );
};

export default Button;