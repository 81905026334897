import * as React from 'react';
import './LoadingRoom.css';
import {useTranslation} from "react-i18next";
import {useFormState} from '../main/ActivationForm/Context/ActivationFormContext';
import {themeOn} from "../../config/apiConfig";

const LoadingRoom = () => {
    const {t} = useTranslation();
    const {
        showThankYou,
        loading,
        nonAuth,
        nonPending,
        processing,
        showBanner,
        tokenHasExpired,
        tokenLowerCase,
        fawError,
        bannerSrc
    } = useFormState();

    const show: boolean = (loading && !processing && !showThankYou && !showBanner && !nonAuth && !(nonPending || tokenHasExpired || tokenLowerCase || fawError));

    if (!themeOn) {
        return (
            <>
                {show &&
                    <div data-testid="waitingRoomContainer" className="waitingRoomContainer">
                        <div className="waitingRoomHeader">
                            <div className="waitingRoomLoadingGraphic"></div>
                            <div
                                className="waitingRoomMessage">{t('loadingroom.message', "Please wait while we finish gathering your order information.")}</div>
                            <div className="waitingRoomHelp">
                                {t('waitingroom.needHelp', "Need Help?")}
                                <a href="https://oc-cx-en.custhelp.com/app/chat/chat_launch" target='_blank'
                                   rel="noopener noreferrer">{t('loadingroom.contactSupport', " Contact Chat Support")}</a>
                            </div>
                        </div>
                        <div className="waitingRoomCarousel">
                            <h3 className="title6">{t('loadingroom.slide1Header', "Cloud Infrastructure")}</h3>
                            <hr className=""/>
                            <h2>{t('loadingroom.slide1Title', "Innovate with Oracle Cloud Infrastructure")}</h2>
                            <p>{t('loadingroom.slide1Content', "Oracle Cloud Infrastructure supports traditional workloads and delivers modern cloud development tools.  It is architected to detect and defend against modern threats, so you can innovate more.  Combine low cost with high performance to lower your TCO.")}</p>
                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        <>
            {show &&
                <div data-testid="waitingRoomContainer" className="waitingRoomContainer">
                    <div className="waitingRoomHeaderThemeOn brandingHeaderColor">
                        <div className="loadingRoomLoadingHeroImage">
                            <img src={bannerSrc} width="50%" alt="Company Logo"/>
                        </div>
                        <div
                            className="waitingRoomMessageThemeOn">{t('loadingroom.message', "Please wait while we finish gathering your order information.")}</div>
                    </div>
                </div>
            }
        </>
    )
}

export default LoadingRoom;
