import * as React from "react";
import {themeOn} from "../config/apiConfig";

type HeaderProps = {
  wrapperClass: string,
  logo: string,
  textClass: string,
  text: string
}

const ActivationPageHeader: React.FC<HeaderProps> = ({wrapperClass, logo, textClass, text}) => {
  const oLogo = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='25' viewBox='0 0 32 25'%3E%3Cg fill='%23C74634'%3E%3Cpath d='M9.9,20.1c-5.5,0-9.9-4.4-9.9-9.9c0-5.5,4.4-9.9,9.9-9.9h11.6c5.5,0,9.9,4.4,9.9,9.9c0,5.5-4.4,9.9-9.9,9.9H9.9 M21.2,16.6c3.6,0,6.4-2.9,6.4-6.4c0-3.6-2.9-6.4-6.4-6.4h-11c-3.6,0-6.4,2.9-6.4,6.4s2.9,6.4,6.4,6.4H21.2'/%3E%3C/g%3E%3C/svg%3E";
  return (
    <header data-testid="avtivation-header">
      <div className={wrapperClass}>
        <div className={`${logo}`} hidden={true}>
          <img src={oLogo} width="auto" height="44px" alt="Console Icon" />
        </div>
        { themeOn ? '' : <div className={textClass}>{text}</div> }
      </div>
    </header>
  );
};

export default ActivationPageHeader;
