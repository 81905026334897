import * as React from 'react';
import './Icon.css';

//Icons Supported: info, error

class Icon extends React.Component {

    render() {
        return (
            <div className={"icon " + this.props.name} onClick={this.props.onClick}></div>
        )
    }
}

export default Icon;
