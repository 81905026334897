import React from 'react';
import Icon from "../Icon/Icon";
import ReactPasswordStrength from 'react-password-strength';
import './RedwoodPassword.css';
import {Trans} from "react-i18next";

class RedwoodPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            type: "password",
            showHideIcon: "showIcon",
            showPassword: false,
            hide: false,
            showInfo: false,
            wrapperClass: ""
        };
    }

    handleBlur = () => {
        if (this.props.pattern) {
            this.setState({error: false});
            this._input.checkValidity();
        }

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    triggerInvalid = () => {
        this.setState ({ error: true });
    }

    toggleType = () => {
        this.type = this.state.type === 'password' ? 'text' : 'password';
        this.setState({type : this.type, showPassword: !this.state.showPassword});
    }

    onClickHandler = () => {
        this.setState({showInfo: true});
    }

    onChangeHandler = (callback) => {
        this.props.onChange(callback.password);
    }

    togglePasswordHideClass = () => {
        var className = "toggleBtn ";
        var icon = this.state.showPassword ? "hideEyeIcon " : "showEyeIcon ";
        var fullClass = className + icon;
        return fullClass;
    }

    render() {
        const escChars = "` ~ > \\";
        return (
            <div className={`${this.props.wrapperClass}`}>
            <label className={`has-float-label passwd ${this.props.validity === false ? 'redBorder': ''}`}>
                { this.props.passwordStrengthIndicator === "true" ? (
                    <ReactPasswordStrength
                        className="customClass"
                        minLength={8}
                        minScore={2}
                        changeCallback={this.onChangeHandler}
                        scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                        inputProps={{ 
                            name: this.props.name, 
                            type:this.state.type, 
                            onBlur: this.handleBlur, 
                            autoComplete: "new-password", 
                            className: "form-control",
                            placeholder: this.props.placeholder,
                            tabIndex:this.props.tabIndex,
                            required:this.props.required ? true : false,
                            pattern:this.props.pattern,
                            onInvalid:this.triggerInvalid,
                            onClick:this.onClickHandler,
                            onFocus:this.onClickHandler
                        }}
                    />
                ) : (
                    <input ref={c => (this._input = c) }
                        autoComplete="new-password"
                        type={this.state.type}
                        name={this.props.name}
                        value={this.props.value}
                        tabIndex={this.props.tabIndex}
                        placeholder={this.props.placeholder}
                        required={this.props.required ? true : false}
                        pattern={this.props.pattern}
                        onBlur={this.handleBlur}
                        onInvalid={this.triggerInvalid}
                        onChange={this.props.onChange}
                    />
                )}
                <span className="inputName">{this.props.label}</span>
                <div onClick={this.toggleType} className={this.togglePasswordHideClass()}/>
            </label>
            { this.state.showInfo === true &&
            <div className={this.props.valid === false ? "hide" : ""}>

                <Icon name={this.props.condition1 ? 'greenCheck' : (this.props.validity === false ? 'error': 'grayCheck')}></Icon>
                <Trans i18nKey="signup.passwordCondition1"> Password must contain a minimum of 8 characters, 1 lowercase, 1 uppercase, 1 numeric, and 1 special character </Trans> .<br/>

                <Icon name={this.props.condition2 ? 'greenCheck' : (this.props.validity === false ? 'error': 'grayCheck')}></Icon>
                <Trans i18nKey="signup.passwordCondition2"> Password cannot exceed 40 characters, contain the users first name, last name, email address, spaces, or {escChars.toLowerCase()} special characters</Trans>.  
            </div>
            }
            <div className={`${this.props.showError && this.props.validity === false ? "" : "hide"}`}>
                <div className="icon errorIcon"></div>
                {this.props.error}
            </div>
        </div>
        );
    }
}

export default RedwoodPassword;
