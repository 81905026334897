import React from "react";
import Button from "../Button/Button"
import "./RedwoodModal.css";
import Icon from "../Icon/Icon";

export interface RedwoodModalProps {
    btnFn?: any,
    buttonName?: string,
    closeBtn?: boolean,
    fullContainer?: boolean,
    noChrome?: boolean,
    noPadding?: boolean,
    onClose?: any,
    show: boolean,
    title?: string,
    wrapperClass?: string
}

const RedwoodModal: React.FC<RedwoodModalProps> = ({ btnFn, buttonName, closeBtn, fullContainer, noChrome, noPadding, onClose, show, title, children, wrapperClass }) => {
    if (!show) {
        return (null);
    }
    return (
        <div className={fullContainer ? 'modalContainer ' + wrapperClass : wrapperClass}>
            <div className={noChrome ? 'modalNoChrome' : 'modal' } id="modal">
                <div className={!noPadding ? 'modalPadding' : ''}>
                    {!noChrome && <h2>{title}</h2>}
                    {closeBtn && <Icon name="close" onClick={onClose} />}
                    <div className="content">{children}</div>
                    <div className="actions">
                        {buttonName &&
                            <Button
                                type="solid"
                                onClick={btnFn ? btnFn : onClose}
                                text={buttonName}>
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default RedwoodModal;