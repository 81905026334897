import * as React from "react";
import {useTranslation} from "react-i18next";
import TrusteComponent from "../../common/Truste/TrusteComponent";
import './Footer.css';
import {themeOn} from "../../config/apiConfig";

const ActivationPageFooter: React.FC<{}> = () => {
    const {t} = useTranslation();

    if (themeOn) {
        return (
            <footer data-testid="avtivation-footer">
                <div className="footer-len-container row">
                    <div className="col-xs-12 col-sm-2 powered-by">
                        <a href="https://www.oracle.com/legal/privacy/marketing-cloud-data-cloud-privacy-policy.html#13">
                            {t("footer.poweredByOracle", "Powered by Oracle")}
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
    return (
        <footer data-testid="avtivation-footer">
            <div className="footer-len-container row">
                <div className="col-xs-12 col-sm-3 copyright">
                    <a href="https://www.oracle.com/legal/copyright.html">
                        {t("footer.copyright", "© 2021 Oracle")}
                    </a>
                </div>
                <span className="pipe"> | </span>
                <div className="col-xs-12 col-sm-4">
                    <a href="https://www.oracle.com/legal/privacy/index.html">
                        {t("footer.terms", "Privacy")}
                    </a>
                    <span>/</span>
                    <a href="https://www.oracle.com/legal/privacy/privacy-choices.html">
                        {t("footer.info", "Do Not Sell My Info")}
                    </a>
                </div>
                <TrusteComponent/>
                <div className="col-xs-12 col-sm-3">
                    <a href="https://www.oracle.com/legal/privacy/marketing-cloud-data-cloud-privacy-policy.html#13">
                        {t("footer.adChoices", "Ad Choices")}
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default ActivationPageFooter;
