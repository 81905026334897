import * as React from "react";
import {fetchAsset} from "../CourierService/FetchService";
import {themeOn, ux_plugin_url} from "../config/apiConfig";
import {useFormDispatch} from "../components/main/ActivationForm/Context/ActivationFormContext";

const ThemeLoader: React.FC<{}> = () => {
    const dispatch = useFormDispatch();
    React.useEffect(() => {
        if (ux_plugin_url === '') {
            revealLogo();
            return;
        }
        const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
        const logo = document.querySelector('.oLogo > img');
        const waitingBanner = document.querySelector('.waitingRoomLoadingHeroImage > img');
        const loadingBanner = document.querySelector('.loadingRoomLoadingHeroImage > img');
        fetchAsset('ux-config.json').then(async (res) => {
            if (res.status === 200) {
                const uxConfig = await res.json();
                if (uxConfig.themes === [] || uxConfig.realmDefaultTheme === null) {
                    return;
                }
                // @ts-ignore
                const themeIdx = uxConfig.themes.findIndex(conf => conf.id === uxConfig.realmDefaultTheme);
                // @ts-ignore
                const activeThemeColorTokens = uxConfig.themes.find(t => t.id === uxConfig.realmDefaultTheme);

                // Add themes colors mapping here:
                const activeThemeColorObj = {
                    "--html-txt-color": activeThemeColorTokens?.styles?.colors?.fgBase,
                    "--link-color": activeThemeColorTokens?.styles?.colors?.primaryBase,
                    "--link-hover-color": activeThemeColorTokens?.styles?.colors?.primaryDarker,
                    "--header-color": activeThemeColorTokens?.styles?.colors?.fgBase,
                    "--header-base-color": activeThemeColorTokens?.styles?.colors?.chBgBase,
                    "--main-base-white": activeThemeColorTokens?.styles?.colors?.white,
                    "--main-base-color": activeThemeColorTokens?.styles?.colors?.bgDarker,
                    "--main-side-color": activeThemeColorTokens?.styles?.colors?.bgLight,
                    "--main-form-color": activeThemeColorTokens?.styles?.colors?.fgDark,
                    "--main-button-bg-color": activeThemeColorTokens?.styles?.colors?.primaryDark,
                    "--main-button-bg-color-disabled": activeThemeColorTokens?.styles?.colors?.primaryLighter,
                    "--blockquote-color": activeThemeColorTokens?.styles?.colors?.fgLighter,
                    "--blockquote-border-color": activeThemeColorTokens?.styles?.colors?.fgLighter,
                    "--selection-bg-color": activeThemeColorTokens?.styles?.colors?.primaryBase,
                    "--selection-color": activeThemeColorTokens?.styles?.colors?.white,
                    "--table-text-color": activeThemeColorTokens?.styles?.colors?.fgBase,
                    "--table-border-color": activeThemeColorTokens?.styles?.colors?.bgDarkest,
                    "--pre-color": activeThemeColorTokens?.styles?.colors?.fgLight,
                    "--pre-border-color": activeThemeColorTokens?.styles?.colors?.bgDarkest,
                    "--pre-bg-color": activeThemeColorTokens?.styles?.colors?.bgLightest,
                    "--db-link-color": activeThemeColorTokens?.styles?.colors.primaryBase,
                    "--link-visited": activeThemeColorTokens?.styles?.colors?.primaryBase,
                    "--main-bg-success": "#508223",
                    "--main-bg-warning": "#AC630C",
                    "--main-bg-danger": "#D63B25",
                    "--main-bg-info": "#00688C",
                    "--main-bg-freeTier": "#7405CE",
                    "--main-bg-warningBanner": "#ff0",
                    "--main-bg-slateGray": "#697778",
                    "--main-bg-tertiaryBgLnk": "#007ccb"
                };

                for (const colorKey in activeThemeColorObj) {
                    // @ts-ignore
                    if (activeThemeColorObj[colorKey]) {
                        // @ts-ignore
                        document.documentElement.style.setProperty(colorKey, activeThemeColorObj[colorKey]);
                    }
                }

                if (favicon) {
                    favicon.setAttribute('href', ux_plugin_url + uxConfig.commonAssets.favicon);
                    favicon.setAttribute('rel', 'icon');
                    document.getElementsByTagName("head")[0].appendChild(favicon);
                }
                if (logo) {
                    let headerLogo;
                    if (!!uxConfig?.themes?.length && uxConfig?.themes[themeIdx]?.assets?.logo) {
                        let logoAsset = uxConfig?.themes?.length && uxConfig?.themes[themeIdx]?.assets?.logo;
                        if (logoAsset.includes('themes')) {
                            headerLogo = `${ux_plugin_url}${uxConfig?.themes[themeIdx]?.assets?.logo}`;
                        } else {
                            headerLogo = `${ux_plugin_url}themes/${uxConfig.realmDefaultTheme}/${uxConfig?.themes[themeIdx]?.assets?.logo}`;
                        }
                    }
                    headerLogo && logo.setAttribute('src', headerLogo);
                }
                if (waitingBanner || loadingBanner) {
                    let heroLogo;
                    if (!!uxConfig?.themes?.length && uxConfig?.themes[themeIdx]?.assets?.logo) {
                        let heroAsset = uxConfig?.themes?.length && uxConfig?.themes[themeIdx]?.assets?.logo;
                        if (heroAsset.includes('themes')) {
                            heroLogo = `${ux_plugin_url}${uxConfig?.themes[themeIdx]?.assets?.logo}`;
                        } else {
                            heroLogo = `${ux_plugin_url}themes/${uxConfig.realmDefaultTheme}/${uxConfig?.themes[themeIdx]?.assets?.logo}`;
                        }
                    }
                    heroLogo && dispatch({
                        type: 'UPDATE_LOGO_IMG_SRC',
                        payload: heroLogo
                    });
                    heroLogo && waitingBanner && waitingBanner.setAttribute('src', heroLogo);
                    heroLogo && loadingBanner && loadingBanner.setAttribute('src', heroLogo);
                }
                if (themeOn || (uxConfig.pageconfig.title && uxConfig.pageconfig.title !== '')) {
                    let title = themeOn && uxConfig.pageconfig?.title !== '' ? uxConfig.pageconfig.title : 'Acme Corp';
                    document.title = title;
                    dispatch({
                        type: 'UPDATE_COMPANY_NAME',
                        payload: title
                    });
                }
            } else {
                console.log("Style not found");
            }
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            revealLogo();
        });
    }, [dispatch]);

    return <></>;
};

const revealLogo = () => {
    const logo = document.querySelector('.oLogo > img');
    logo && logo.parentElement && logo.parentElement.attributes.removeNamedItem("hidden");
};

export default ThemeLoader;