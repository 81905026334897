import * as React from 'react';
import './NonAuthRoom.css';
import {useTranslation} from "react-i18next";
import RedwoodModal from '../../common/Modal/RedwoodModal';
import { useFormState } from '../main/ActivationForm/Context/ActivationFormContext';
import Button from '../../common/Button/Button';

const NonAauthRoom = () => {
    const {processing, showThankYou, showBanner, tokenLowerCase, nonAuth, fawError} = useFormState();
    const show: boolean = (!processing && !showThankYou && !showBanner &&!tokenLowerCase && nonAuth && !fawError);
    const {t} = useTranslation();
    const doClick = () => {
        window.open('https://www.oracle.com/cloud/architecture-center/','_blank');
    }
    return (  
        <>
        {nonAuth &&
            <div data-testid="nonauth-container" className={"ty-wrapper"}>
                <RedwoodModal show={show} title={t('nonAuth.title', "Cloud Infrastructure")} fullContainer={true}>
                    <hr className="thankyou" />
                    <h3>{t('nonAuth.message', "Thank you for your interest in Oracle Cloud!")}</h3>
                    <p>{t('nonAuth.creating', "Are you trying to add an order to an existing account?")}</p>
                    <Button style={{marginTop:0}} type="solid" onClick={() => doClick()} text={t('nonAuth.btnTxt', "Learn more")} /> 
                    <p>{t('nonAuth.signup', "Looking to try Oracle Cloud Free Tier?")} <a href="https://signup.oraclecloud.com/" target='_blank' rel="noopener noreferrer">{t('nonAuth.linkTxt',"Start for free.")}</a></p>
                </RedwoodModal>
            </div>
        }
        {fawError &&
        <div data-testid="faw-order-container" className={"ty-wrapper"}>
            <RedwoodModal show={show} title={t('nonAuth.title', "Cloud Infrastructure")} fullContainer={true}>
            </RedwoodModal>
        </div>
        }
            {tokenLowerCase &&
            <div data-testid="nonauth-container" className={"ty-wrapper"}>
                <RedwoodModal show={show} title={t('nonAuth.title', "Cloud Infrastructure")} fullContainer={true}>
                </RedwoodModal>
            </div>
            }
        </>
    )
}

export default NonAauthRoom;
