import * as React from "react";
import LoadingRoom from "./LoadingRoom/LoadingRoom";
import { FormProvider } from "./main/ActivationForm/Context/ActivationFormContext";
import NonAuthRoom from "./NonAuthRoom/NonAuthRoom";
import NonPendingRoom from "./NonPendingRoom/NonPendingRoom";
import OrgBanner from "./OrgBanner/OrgBanner";
import ThankYou from "./ThankYou/ThankYou";
import WaitingRoom from "./WaitingRoom/WaitingRoom";
import ThemeLoader from "../common/ThemeLoader";

const ActivationPageWrapper: React.FC<{children: any}> = ({children}) => {
  return (
    <main data-testid="main" id="main">
      <FormProvider>
        <div data-testid="row" className="row">
          {children}
        </div>
        <ThemeLoader/>
        <OrgBanner />
        <NonAuthRoom />
        <NonPendingRoom />
        <WaitingRoom />
        <LoadingRoom />
        <ThankYou />
      </FormProvider>
    </main>
  );
};

export default ActivationPageWrapper;
