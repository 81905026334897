import './WaitingRoom.css';
import { useTranslation } from "react-i18next";
import { useFormState } from '../main/ActivationForm/Context/ActivationFormContext';
import { themeOn } from "../../config/apiConfig";
import * as React from "react";

const WaitingRoom = () => {
    const {t} = useTranslation();
    const {
        processing,
        showThankYou,
        bannerSrc
    } = useFormState();

    if (!themeOn) {
        return (
            <>
                {(processing && !showThankYou) &&
                    <div className="waitingRoomContainer">
                        <div className="waitingRoomHeader">
                            <div className="waitingRoomLoadingGraphic"></div>
                            <div className="waitingRoomMessage">{t('waitingroom.message', "Please wait while we finish processing your request.")}</div>
                            <div className="waitingRoomHelp">
                                {t('waitingroom.needHelp', "Need Help?")}
                                <a href="https://oc-cx-en.custhelp.com/app/chat/chat_launch" target='_blank' rel="noopener noreferrer">{t('waitingroom.contactSupport', " Contact Chat Support")}</a>
                            </div>
                        </div>
                        <div className="waitingRoomCarousel">
                            <h3 className="title6">{t('waitingroom.slide1Header', "Cloud Infrastructure")}</h3>
                            <hr className="" />
                            <h2>{t('waitingroom.slide1Title', "Innovate with Oracle Cloud Infrastructure")}</h2>
                            <p>{t('waitingroom.slide1Content', "Oracle Cloud Infrastructure supports traditional workloads and delivers modern cloud development tools.  It is architected to detect and defend against modern threats, so you can innovate more.  Combine low cost with high performance to lower your TCO.")}</p>
                        </div>
                    </div>
                }
            </>
        );
    }
    return (
        <>
            {(processing && !showThankYou) &&
                <div className="waitingRoomContainer">
                    <div className="waitingRoomHeaderThemeOn brandingHeaderColor">
                        <div className="waitingRoomLoadingHeroImage">
                            <img src={bannerSrc} width="50%" alt="Company Logo" />
                        </div>
                        <div className="waitingRoomMessage">{t('waitingroom.message', "Please wait while we finish processing your request.")}</div>
                    </div>
                </div>
            }
        </>
    )
}

export default WaitingRoom;
