import * as React from 'react';
import './ThankYou.css';
import { useTranslation } from "react-i18next";
import RedwoodModal from '../../common/Modal/RedwoodModal';
import { useFormState } from '../main/ActivationForm/Context/ActivationFormContext';
import { themeOn } from "../../config/apiConfig";

const ThankYou = () => {
    const {processing, showThankYou} = useFormState();
    const show: boolean = (!processing && showThankYou);
    const {t} = useTranslation();

    if (!themeOn) {
        return (
            <>
            {showThankYou &&
                <div className={"ty-wrapper"}>
                    <RedwoodModal show={show} title={t('thankYou.title', 'Cloud Infrastructure')} fullContainer={true}>
                        <hr className="thankyou" />
                        <h3>{t('thankYou.message', 'Thank you for signing up for Oracle Cloud!')}</h3>
                        <p>{t('thankYou.creating', 'We are creating your cloud account. We’ll email you with further instructions when your cloud account is ready so you can get your services up and running.')}</p>
                        <p><a href="https://www.oracle.com/cloud/" target='_blank' rel="noopener noreferrer">{t('thankYou.read', 'Learn more about managing your administrative roles')}</a></p>
                    </RedwoodModal>
                </div>
            }
            </>
        )
    }
    return (
        <>
        {showThankYou &&
            <div className={"ty-wrapper"}>
                <RedwoodModal show={show} title={t('thankYou.title', 'Cloud Infrastructure')} fullContainer={true}>
                    <hr className="thankyou" />
                    <h3>{t('thankYou.alloyMessage', 'Thank you for signing up!')}</h3>
                    <p>{t('thankYou.creating', 'We are creating your cloud account. We’ll email you with further instructions when your cloud account is ready so you can get your services up and running.')}</p>
                </RedwoodModal>
            </div>
        }
        </>
    )
}

export default ThankYou;
