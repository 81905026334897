export const regExp = {
  "identityDomainName": "^[ ]*[a-z][a-z0-9]*[ ]*$",
  "personName" : "^([ ]+)*[a-zA-Z0-9\\u00C0-\\uFFFF][',. a-zA-Z0-9\\u00C0-\\uFFFF-]*([ ]+)*$",
  "email" : "[a-zA-Z0-9!#$%'*+/=?^_`{|}~&amp;-]+([.][a-zA-Z0-9!#$%'*+/=?^_`{|}~&amp;-]+)*@[a-zA-Z0-9]+[a-zA-Z0-9]*([-][a-zA-Z0-9]+)*([.][a-zA-Z]+[a-zA-Z0-9]*([-][a-zA-Z0-9]+)*)+",
  "number" : "^[0-9]*$",
  "alphaUpperLower" : "^[a-zA-Z]*$",
  "organization" : "^[a-zA-Z0-9\\u00A0-\\uFFFF][a-zA-Z0-9&\\u00A0-\\uFFFF',. ()@-]{0,199}[ ]*$",
  "tenancy" : "^[a-z][a-z0-9]*$",
  "altEnglishCompanyName": "^[a-zA-Z0-9][a-zA-Z0-9&',. ()@-]{0,199}[ ]*$",
  "altEnglishName": "^[a-zA-Z][a-zA-Z0-9&',. -]{0,199}[ ]*$",
  "letters" : "^[a-zA-Z]*$",
  "letterNumbers":"^[a-zA-Z][a-zA-Z0-9]*$",
  "smsValidationCode" : "^[0-9]{6,12}[ ]*$",
  "phoneNumber": "^(?:[0-9] ?){6,14}[0-9]$"
}

