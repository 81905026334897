import { courier_service_url, env } from '../config/apiConfig';

const SendMetrics = (props) => {
    fetch(
      `${courier_service_url}/sendMetrics`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          source: 'oci_signup_client',
          type: 'metrics',
          timestamp: Date.now(),
          metadata: [],
          sampling: 1,
          ...props,
          name: insertEnvToMetricName(props.name)
        })
      }
    ).then(async res => {

    }).catch(error => {
      console.log(error);
    });
}

const insertEnvToMetricName = (name) => {
    if (["PINTLAB", "PINTLAB2", "PRD", "DEV", "UAT", "UAT2", "UAT3", "STG"].includes(env.toUpperCase())) return name;
    const nameParts = name.split('.');
    nameParts.splice(1, 0, env.toUpperCase());
    const transformedName = nameParts.join('.');

    return transformedName;
}

export default SendMetrics;
