import * as React from 'react';
import './OrgBanner.css';
import {useTranslation} from "react-i18next";
import RedwoodModal from '../../common/Modal/RedwoodModal';
import { useFormState, useFormDispatch } from '../main/ActivationForm/Context/ActivationFormContext';
import Icon from '../../common/Icon/Icon';

const OrgBanner = () => {
    const {processing, showThankYou, showBanner, nonAuth, bannerContent, tokenLowerCase, fawError} = useFormState();
    const show: boolean = (!processing && !showThankYou && !nonAuth && showBanner && !tokenLowerCase && !fawError);
    const {t} = useTranslation();
    const dispatch = useFormDispatch();
    const closeBannerModal = () => {
        dispatch({
          type:'UPDATE_SHOW_BANNER',
          payload: "boolean",
          boolean: false
        });
        //Add class to body for overlay
        const wrapper = document.getElementById("main");
        if (wrapper) {
          if (wrapper.classList.contains('modal-open')) {
            wrapper.classList.remove('modal-open');
          } else {
            wrapper.classList.add('modal-open');
          }
        } 
      }

    return (
        <>
        {showBanner &&
            <div className={"ty-wrapper"}>
                <RedwoodModal
                    show={show}
                    title={t('nonAuth.title', "Cloud Infrastructure")}
                    fullContainer={true} buttonName={bannerContent?.level !== "error" ? "Close" : "" }
                    onClose={() => closeBannerModal()} 
                >
                    <div>
                        <Icon name={bannerContent?.level}/>
                        {bannerContent.bannerTitle}
                    </div>
                    <div>
                        <p>{bannerContent.bannerDetails}</p>
                    </div>
                    <p><a href="https://www.oracle.com/cloud/" target='_blank' rel="noopener noreferrer">{t('nonAuth.read', "You can read more about Oracle Cloud Infrastructure.")}</a></p>
                </RedwoodModal>
            </div>
        }
        </>
    )
}

export default OrgBanner;
