import * as React from "react";

type IntroBlockContentProps = {
    title: string,
    content: any,
    hide?: boolean
}

const SidebarInfoBlockContent: React.FC<IntroBlockContentProps> = ({title, content, hide = false, children}) => {
    if (hide) return (<></>);
    return (
        <div>
            <h2>{title}</h2>
            <p>{content}</p>
            {children}
        </div>
    );
};

export default SidebarInfoBlockContent;
