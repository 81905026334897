import * as React from "react";
import "./CircularLoader.css"

const CircularLoader = (props) => {
  const { ...rest } = props;
  return (
    <div
      data-testid="circular-loader"
      {...rest}
      className={"loader " + props.className}
    >
      <div className="loader-well" />
      <div className="loader-track" />
    </div>
  )
}

export default CircularLoader;
