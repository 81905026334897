import * as React from 'react';
import RedwoodPassword from "../../../../common/Password/RedwoodPassword"
import {useTranslation} from "react-i18next";
import { useFormDispatch, useFormState } from "../Context/ActivationFormContext"

const PasswordComponent = (props: {
  updateTouched: (arg0: string, arg1: boolean) => void;
  updatePasswordErrorFlag: (arg0: string, arg1: boolean) => void;
  passwordError: { passwordValid: any; matchPassword: any; }; 
}) => {
  const dispatch = useFormDispatch()
  const state = useFormState();
  const {t} = useTranslation();
  
  const [passwordValidation, setPasswordValidation] = React.useState({
    condition1: false,
    condition2: true,
  });

  const [passwordCriteria, setPasswordCriteria] = React.useState({
    minPasswordLen: false,
    maxPasswordLen: false, 
    checkFirstName: false, 
    checkLastName: false,
    checkEmail: false,
    checkPasswordRule: false
  })

  const validatePassword = (password: string) => {
    props.updateTouched('password', true);
    dispatch({
      type: 'UPDATE_PASSWORD',
      payload: password
    });
    
    const minAcceptableLen = 8;
    const maxAcceptableLen = 40;
    const curLen = password.length.valueOf();

    const isCheckable = (value?: string) => {
      if(value) {
        if(value !== "" && value.length > 0) {
          return true;
        }
      }
      return false;
    }

    const emailChecker = ():boolean => {
      let flag = false;
      if(isCheckable(state.emailAddress)) {
        flag = password.toLowerCase().indexOf(state.emailAddress.toLowerCase()) > -1 ? false : true;
      }
      return flag;
    }
    const emailCheck = emailChecker();

    const firstNameChecker = ():boolean => {
      let flag = false;
      if(isCheckable(state.firstName)) {
        flag = password.toLowerCase().indexOf(state.firstName.toLowerCase()) > -1 ? false : true;
      }
      return flag;
    }
    const firstNameCheck = firstNameChecker();
    
    const lastNameChecker = ():boolean => {
      let flag = false;
      if(isCheckable(state.lastName)) {
        flag = password.toLowerCase().indexOf(state.lastName.toLowerCase()) > -1 ? false : true;
      }
      return flag;
    }
    const lastNameCheck = lastNameChecker();
    
    const passwordRuleCheck = (/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"|,./?])[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"|,./?]+$/.test(password));

    setPasswordCriteria(passwordCriteria => {
      return {
        ...passwordCriteria,
        minPasswordLen: minAcceptableLen <= curLen,
        maxPasswordLen: curLen <= maxAcceptableLen, 
        checkFirstName: firstNameCheck, 
        checkLastName: lastNameCheck,
        checkEmail: emailCheck,
        checkPasswordRule: passwordRuleCheck
      }
    });
  }

  React.useEffect((() => {
    if( passwordCriteria.checkEmail && passwordCriteria.maxPasswordLen && passwordCriteria.checkFirstName && passwordCriteria.checkLastName) {
      setPasswordValidation(passwordValidation => {
        return {...passwordValidation, condition2: true}
      });
    } else if(passwordCriteria.checkEmail === false || passwordCriteria.maxPasswordLen === false 
      || passwordCriteria.checkFirstName === false || passwordCriteria.checkLastName === false){
      setPasswordValidation(passwordValidation => {
        return {...passwordValidation, condition2: false}
      });
    }
    if(passwordCriteria.minPasswordLen && passwordCriteria.checkPasswordRule) {
        setPasswordValidation(passwordValidation => {
          return {...passwordValidation, condition1: true}
        });
    } else if(passwordCriteria.minPasswordLen === false || passwordCriteria.checkPasswordRule === false) {
        setPasswordValidation(passwordValidation => {
          return {...passwordValidation, condition1: false}
      });
    }
  }), [passwordCriteria])

  const setConfirmedPassword = (password: string) => {
    dispatch({
      type: 'UPDATE_CONFIRM_PASSWORD',
      payload: password
    });
    props.updateTouched('confirmPassword', true);
  }

  const matchPassword = (e: any) => {
    if(state.password !== state.confirmPassword) {
      props.updatePasswordErrorFlag('matchPassword', false);
    } else {
      props.updatePasswordErrorFlag('matchPassword', true);
    }
  }

  const componentText = {
    lable: {
      password: t('password.pass', "Password"),
      confirmPassword: t('password.confirmPass', "Confirm Password")
    },
    placehloder: {
      password: t('password.pass', "Password"),
      confirmPassword: t('password.confirmPass', "Confirm Password")
    },
    error: {
      confirmed: t('password.confirmedError', "Passwords do not match")
    }
  }

  return (
    <>
      <RedwoodPassword
        wrapperClass="col-md-12 col-xs-12"
        label={componentText.lable.password}
        name="password"
        placeholder={componentText.placehloder.password}
        passwordStrengthIndicator = "true"
        valid={true}
        showError={false}
        validity={props.passwordError.passwordValid}
        condition1={passwordValidation.condition1}
        condition2={passwordValidation.condition2}
        onChange={(e: string) => validatePassword(e)} 
        onBlur={(e: string) => {
          if(passwordValidation.condition1 && passwordValidation.condition2) {
            props.updatePasswordErrorFlag('passwordValid', true);
          } else {
            props.updatePasswordErrorFlag('passwordValid', false);
          }
        }}
      />
      <RedwoodPassword
        wrapperClass="col-md-12 col-xs-12"
        label={componentText.lable.confirmPassword}
        name="matchPassword" 
        placeholder={componentText.placehloder.confirmPassword}
        valid={false}
        showError={true}
        validity={props.passwordError.matchPassword}
        error={componentText.error.confirmed}
        onChange={(e: { target: any; }) => setConfirmedPassword(e.target.value)}
        onBlur={(e: any) => matchPassword(e)}
      />
    </>
  )
}

export default PasswordComponent;
