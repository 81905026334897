import {courier_service_url} from '../config/apiConfig';

const SendLogs = (props) => {
    fetch(
      `${courier_service_url}/sendLogging`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          source: 'oci_signup_client',
          type: 'logging',
          timestamp: Date.now(),
          metadata: [],
          ...props
        })
      }
    ).then(async res => {
    }).catch(error => {
      console.log(error);
    });
}

export default SendLogs;
