import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFormState } from "../main/ActivationForm/Context/ActivationFormContext";
import * as _ from "lodash";

type SubscriptionContentProps = {
  title: string
}

const SidebarInfoBlockContent: React.FC<SubscriptionContentProps> = ({title}) => {
  const {t} = useTranslation();
  const { subscriptionIds, orderNumber, regionName, multicloudOrderReference, orderType, upstreamEnvironment } = useFormState();

  return (
    <div>
      <h2 className={"title6"}>{title}</h2>
      {_.isString(upstreamEnvironment) &&
          ['ORP_AZURE', 'ODBG_GCP'].includes(upstreamEnvironment.toUpperCase()) &&
          _.isString(orderType) &&
          orderType.toUpperCase() === 'PUBLIC' &&
          multicloudOrderReference !== '' ?
          <p key={"order"} className={"subinfo"}>
            <span className={"title8"}>{t('subscriptionBlock.orderRefId', "Order Reference ID") + ": "}</span>{multicloudOrderReference}
          </p> :
          <p key={"order"} className={"subinfo"}>
            <span className={"title8"}>{t('subscriptionBlock.orderNum', "Order Number") + ": "}</span>{orderNumber.split('c')[0]}
          </p>
      }
      {subscriptionIds && subscriptionIds.map((id, index) => (
        <p key={index} className={"subinfo"}>
          <span className={"title8"}>{t('subscriptionBlock.subId', "Subscription ID") + ": "}</span>{id}
        </p>
      ))}
      {
        regionName
          && regionName!== ''
          && <p>
            <span className="title8">{t('subscriptionBlock.dcrTitle', "Data Center Region")}: </span>{regionName}
          </p>
      }
    </div>
  );
};

export default SidebarInfoBlockContent;
