import * as React from "react";
import './SidebarIntroBlock.css';

type IntroBlockProps = {
  wrapperClass: string
}

const SidebarIntroBlock: React.FC<IntroBlockProps> = ({wrapperClass, children}) => {
  return (
    <div className={wrapperClass}  data-testid="sidebar-intro">
      {children}
    </div>
  );
};

export default SidebarIntroBlock;
