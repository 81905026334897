import * as React from "react";
import * as _ from "lodash";
import './ActivationForm.css';
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { useFormState, useFormDispatch } from "./Context/ActivationFormContext"
import RedwoodInputText from '../../../common/Input/RedwoodInputText';
import RedwoodSelect from '../../../common/Select/RedwoodSelect';
import { regExp } from '../../../common/Validators';
import PasswordComponent from "./Password/PasswordComponent";
import * as Bowser from "bowser";
import { themeOn } from "../../../config/apiConfig";
import SendMetrics from "../../../CourierService/SendMetrics";
import SendLogs from "../../../CourierService/SendLogs";
import TermsOfUseComponent from "../../TermsOfUse/TermsOfUseComponent";
import Notifier from "../../../common/Notifier/Notifier";
import RedwoodModal from "../../../common/Modal/RedwoodModal";
import { keysIn } from "lodash";
import {
  fetchAccountNameValidation,
  fetchBanner,
  fetchRegions,
  fetchStateId
} from "../../../CourierService/FetchService";
import RedwoodCheckbox from "../../../common/Checkbox/RedwoodCheckbox";
import PhoneInput from "react-phone-input-2";
import countries from "../../../common/CountriesInfo.json";
import {isRestrictedCountry} from "../../../common/Helper";

const FormDisplay = () => {
  const dispatch = useFormDispatch()
  const [touched, setTouched] = React.useState({});
  const [filterRegions, setFilterRegions] = React.useState<boolean>(false);
  const [regionGroups, setRegionGroups] = React.useState<any[]>([]);
  const [acceptRegionRestrictions, setAcceptRegionRestrictions] = React.useState(false);
  const [homeRegionList, setHomeRegionList] = React.useState<any[]>([]);
  const [perfillError, setPerfillError] = React.useState<boolean>(false);
  const [passwordError, setPasswordError] = React.useState({passwordValid: null, matchPassword: null});
  const [tenancyNameChanged, setTenancyNameChanged] = React.useState<boolean>(false);
  const [updateStateList, setUpdateStateList] = React.useState(false);
  const [customerTypePublicOffer, setCustomerTypePublicOffer] = React.useState<string>('INDIVIDUAL');
  const {t} = useTranslation();
  const token = new URLSearchParams(window.location.search).get('activationToken');
  const errorState = {isValid: true, value: '', errorMsg: ''};
  const {
    user_session,
    apiError,
    render,
    firstName,
    lastName,
    emailAddress,
    tenancyName,
    dataCenterRegion,
    dataCenterRegionId,
    serviceGroupNames,
    homeRegion,
    regionGroup,
    homeRegions,
    showBanner,
    isInternalCustomerType,
    domainType,
    tenancyTypes,
    partyName,
    alternatePartyName,
    alternateEnglishName,
    customerPhoneNumber,
    address,
    countryObject,
    countryInfo,
    statesList,
    selectedState,
    selectedProvince,
    addressValidationMessage,
    featureFlags,
    call2tenls,
    publicOffer,
    restrictedCountry,
    orderNumber
  } = useFormState();

  const unavailableError = React.useMemo(() => t('activationForm.error.unavailable', 'The chosen Cloud Account Name is unavailable'), [t]);

  const formText: {[key: string]: {[key: string]: string | any }} = {
    label: {
      newAccountInformation: t('activationForm.label', "New Cloud Account Information"),
      firstName: t('activationForm.firstName', 'First Name'),
      lastName: t('activationForm.lastName', 'Last Name'),
      email: t('activationForm.email', 'Email'),
      tenancyName: t('activationForm.tenancyName', 'Cloud Account Name'),
      regionGroup: t('activationForm.regionGroup', 'Region Group'),
      homeRegion: t('activationForm.homeRegion', 'Home Region'),
      tenancyType: t('activationForm.tenancyType', 'Cloud Account Type'),
      partyName: t('activationForm.partyName', 'Legal Company Name'),
      alternatePartyName: t('activationForm.alternatePartyName', 'Alternate English Legal Company Name'),
      address1: t('personalInfo.address1', 'Street Address'),
      address2: t('personalInfo.address2', 'Apartment, Unit, Floor or PO Box'),
      address3: t('personalInfo.address3', 'Address Line 3'),
      address4: t('personalInfo.address4', 'Address Line 4'),
      city: t('personalInfo.city', 'City'),
      state: t('personalInfo.state', 'State'),
      province: t('personalInfo.province', 'Province'),
      postalCode: t('personalInfo.postalCode', 'Postal Code'),
      county: t('personalInfo.county', 'County/Municipality'),
      altEnglishName: t('personalInfo.altName', 'Alternate Name'),
      altNamePlaceholder: t('personalInfo.altNamePlaceholder', 'Enter your full name in English characters')
    },
    info: {
      tenancyName: <Trans i18nKey="activationForm.tenancyNameInfoTemp">Avoid including personal or confidential information when creating a cloud account name, since it cannot be changed later, and the name will be visible in the login URL. <br/><br/>The Cloud Account Name must be lowercase, start with a letter, contain no spaces or special characters, and be 25 or less characters long. The name will be assigned to your company's or organization's environment when signing into the Console.</Trans>,
      homeRegion: <Trans i18nKey="activationForm.homeRegionInfo">See <a href="https://www.oracle.com/cloud/cloud-regions/data-regions/#northamerica" target='_blank' rel="noopener noreferrer">OCI service availability</a> by Regions. SaaS applications are provisioned in the geo-region specified on your order.</Trans>,
      tenancyType: <Trans i18nKey="activationForm.tenancyTypeInfo">Lightweight Domain Tenancy should be used by Internal Oracle Service teams that need a tenancy for service principals, or any Tenancy that is used as part of region build or host/run service components.</Trans>
    },
    checkbox: {
      commercial: t('activationForm.checkboxCommercial', 'By selecting this box, you acknowledge that your home region cannot be changed after this step in the activation process.'),
      serbiaCommercial: t('activationForm.serbiaCommercial', 'By selecting this box, you acknowledge that your home region cannot be changed after this step in the activation process. You will only be able to create tenancies in the Oracle Serbia Central data center, in accordance with Oracle region isolation design principles. Resources in a tenancy inside Oracle Serbia Central Region cannot natively integrate with Oracle Cloud Services in other commercial region groups.'),
      euSovereign: <Trans i18nKey="activationForm.checkboxEuSovereign">By selecting this box, you acknowledge that your home region cannot be changed after this step in the activation process. Please see the <a href="https://www.oracle.com/contracts/cloud-services/" target='_blank' rel="noopener noreferrer">Service Descriptions and Oracle PaaS and IaaS Public Cloud Services Pillar Document</a> for additional details and terms that apply to the EU Sovereign Cloud. This region has restrictions and cannot natively integrate with other Cloud Services in other Regions.</Trans>
    },
    validation: {
      altEnglishName: t('regExpr.altEnglishName', 'Use only English letters and spaces.')
    }
  }

  const [error, setError] = React.useState({
    firstName:errorState,
    lastName:errorState,
    emailAddress:errorState,
    tenancyName:errorState,
    regionGroup:errorState,
    homeRegion:errorState,
    partyName: errorState,
    alternatePartyName: errorState,
    altEnglishName: errorState,
    customerPhoneNumber: errorState,
    address1: errorState,
    address2: errorState,
    address3: errorState,
    address4: errorState,
    city: errorState,
    postalCode: errorState,
    state: errorState,
    province: errorState,
    country: errorState,
    county: errorState
  });

  const resetErrorState = () => {
    setError({
      firstName:errorState,
      lastName:errorState,
      emailAddress:errorState,
      tenancyName:errorState,
      regionGroup:errorState,
      homeRegion:errorState,
      partyName: errorState,
      alternatePartyName: errorState,
      altEnglishName: errorState,
      customerPhoneNumber: errorState,
      address1: errorState,
      address2: errorState,
      address3: errorState,
      address4: errorState,
      city: errorState,
      postalCode: errorState,
      state: errorState,
      province: errorState,
      country: errorState,
      county: errorState
    });
  }

  const getErrorText = (type?: string, insert?: string) => {
    switch (type) {
      case "required":
        return <Trans i18nKey="activationForm.error.required">A value for {{insert}} is required.</Trans>;
      case "length":
        return <Trans i18nKey="activationForm.error.length">Cloud account name must be 25 or less characters long.</Trans>;
      case "regExpr":
        return <Trans i18nKey="activationForm.error.regExpr">Input requirements for {{insert}} not met.</Trans>;
      case "tenancyReq":
        return <Trans i18nKey="activationForm.error.tenancyReq">Cloud account does not meet requirements.</Trans>
      default:
        return t('activationForm.error.gen', 'Error');
    }
  }

  const passwordErrorFlag = (name: any, value: any) => {
    setPasswordError(passwordError => {
      return{...passwordError, ...{[name]: value}}
    });
  };

  const setApiError = React.useCallback(() => {
    if (!apiError) {
      dispatch({
        type:'UPDATE_APIERROR',
        payload: 'boolean',
        boolean: true
      });
    } else {
      setPerfillError(true);
    };
  },[dispatch, apiError]);

  const onChangeHandler = (e: { target: { name: any; }; }, action: string, payload: any) => {
    dispatch({
      type:`${action}`,
      payload: payload
    });
    setTouched({...touched, [e.target.name]: true})
  }

  const onBlurHandler = (e: { target: { value: string; name: string; }; }, pattern: string, action: string, message?: string) => {
    let val = e.target.value.trim()
    const name = e.target.name;
    dispatch({type: `${action}`, payload: val});

    setTouched({...touched, [name]: false})

    if (name === "postalCode" && !val.match(pattern)) {
      setError(error => {
        return {...error, ...{[name]: {isValid: false, value: '', errorMsg: _.isString(message) ? t(`regExpr.${message}`) : ''}}}
      });
      setTouched({...touched, [name]: true})
    } else if (pattern === '-1') {
      setError(error => {
        return {...error, ...{[name]: {isValid: true, value: val, errorMsg: ''}}}
      });
      setTouched({...touched, [name]: true})
    } else if(val.length === 0) {
      setError(error => {
        return {...error, ...{[name]: {isValid: false, value: '', errorMsg: message && message !== '' ? message : getErrorText("required", formText.label[name])}}}
      });
    } else if(!val.match(pattern) || val === '') {
      setError(error => {
        return {...error, ...{[name]: {isValid: false, value: '', errorMsg: message && message !== '' ? message : getErrorText("regExpr", formText.label[name])}}}
      });
    } else {
      setError(error => {
        return {...error, ...{[name]: {isValid: true, value: val, errorMsg: ''}}}
      });
      setTouched({...touched, [name]: true})
    }
  }

  const changeCustomerType = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const { value } = e.currentTarget;
    dispatch({ type: 'UPDATE_CUSTOMER_TYPE_PO', payload: value });
    setCustomerTypePublicOffer(value);
    setAcceptRegionRestrictions(false);
  };

  const updatePartyNameEvent = (type: string) => {
    switch (type) {
      case 'individual':
        onBlurHandler({target: {value: `${firstName} ${lastName}`, name: 'partyName'}}, regExp.organization, 'UPDATE_PARTY_NAME');
        break;
      case 'organization':
      default:
        onBlurHandler({target: {value: '', name: 'partyName'}}, regExp.organization, 'UPDATE_PARTY_NAME');
        break;
    }
  };

  const countriesList = () => {
    const keys:any = [];

    Object.keys(countries.countries).forEach((item) => {
      keys.push({ value: item, label: t(`countries.${item}`) });
    });

    keys.sort((a:any, b:any) => ((a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1));
    return keys;
  };

  const getCountyLabel = () => {
    // Check for edge cases and display if necessary.
    // This is required because you can't store translated words in translation files and they're
    // not intended to translate further.
    if (_.get(countryInfo, 'addressFields.County[1]', '') !== '') {
      if (address.country === 'MX') {
        return 'Municipio';
      }

      if (address.country === 'CL') {
        return 'Comuna';
      }
      return t(`personalInfo.${_.get(countryInfo, 'addressFields.County[1]')}`);
    }
    return t('personalInfo.county', 'County');
  };

  // Check Available Tenancy Name
  React.useEffect(() => {
    function setTenancyError(setting: boolean) {
      if (setting) {
        setError(error => {
          return {...error, ...{tenancyName: {isValid: false, value: '', errorMsg: unavailableError.toString()}}}
        });
      } else {
        setError(error => {
          return {...error, ...{tenancyName: {isValid: true, value: tenancyName, errorMsg: ''}}}
        });
        setTouched({...touched, tenancyName: true})
      };
    }

    if (tenancyNameChanged) {
      fetchAccountNameValidation(tenancyName, call2tenls, homeRegion, domainType)
      .then(async accountNameValidationResult => {
        setTenancyNameChanged(false)
        if(![200, 204].includes(accountNameValidationResult.status)) {
          setTenancyError(true);
          SendMetrics({name:"ActivationValidateTenancyName.Count.Metrics.Error", value:1, userSession:user_session});
        } else {
          setTenancyError(false);
          SendMetrics({name:"ActivationValidateTenancyName.Count.Metrics", value:1, userSession:user_session});
        }
      }).catch(error => {
        SendLogs({severity: 40000, message: `Validate updated tenancy name fail: ${error.message}, orderNumber: ${orderNumber}`, userSession:user_session});
        SendMetrics({name:"ActivationValidateTenancyName.Count.Metrics.Fail", value:1, userSession:user_session});
      });
    }
  },[tenancyName, tenancyNameChanged, touched, setTouched, unavailableError, user_session, domainType, featureFlags, homeRegion, call2tenls, orderNumber]);


  const onBlurTenancyNameHandler = (e: { target: { value: string; name: any; }; }, pattern: string, action: string) => {
    let val = e.target.value.trim()
    const name = e.target.name;
    dispatch({type: `${action}`, payload: val});

    setTouched({...touched, [name]: false})

    if(val.length === 0) {
      setError(error => {
        return {...error, ...{[name]: {isValid: false, value: '', errorMsg: getErrorText("required", formText.label[name])}}}
      });
    } else if (val.length > 25) {
      setError(error => {
        return {...error, ...{[name]: {isValid: false, value: '', errorMsg: getErrorText("length", formText.label[name])}}}
      });
    } else if(!val.match(pattern) || val === '') {
      setError(error => {
        return {...error, ...{[name]: {isValid: false, value: '', errorMsg: getErrorText("tenancyReq", formText.label[name])}}}
      });
    } else {
      setTenancyNameChanged(true);
    }
  }

  const updateTouched = (name: any, value: any) => {
    setTouched(touched => {
      return{...touched, ...{[name]: value}}
    });
  };

  const checkboxTextRegionGroups = (region: string) => {
    const defaultText = formText.checkbox.commercial;

    if (region) {
      if (region.includes('EU Sovereign')) {
        return formText.checkbox.euSovereign;
      }
      if (region.includes('Serbia')) {
        return formText.checkbox.serbiaCommercial;
      }
    }
    return defaultText;
  }

  // Browser, OS and Platform Metrics
  React.useEffect(() => {
    const userAgent = Bowser.parse(window.navigator.userAgent);

    //Browser
    const browserName = userAgent.browser.name? userAgent.browser.name : "";
    const knownBrowserList: {[key: string]: string} = {
      'Chrome': 'Chrome.Count.Metrics',
      'Safari': 'Safari.Count.Metrics',
      'Firefox': 'Firefox.Count.Metrics',
      'Microsoft Edge': 'Edge.Count.Metrics',
      'Internet Explorer': 'IE.Count.Metrics'
    }

    if(knownBrowserList[browserName]) {
      SendMetrics({name: "Activation." + knownBrowserList[browserName], value:1, userSession: user_session});
    } else {
      SendMetrics({name: "ActivationUnknown.Count.Metrics", value: 1, userSession: user_session});
    }

    //OS
    const osname = userAgent.os.name? userAgent.os.name : "";
    const knownOSList: {[key: string]: string} = {
      'macOS': 'Mac.Count.Metrics',
      'Linux': 'Linux.Count.Metrics',
      'Windows': 'Windows.Count.Metrics'
    }

    if(knownOSList[osname]) {
      SendMetrics({name: "Activation." + knownOSList[osname], value: 1, userSession: user_session});
    }

    //Platform
    const platform = userAgent.platform.type? userAgent.platform.type : "";
    const knownPlatformList: {[key: string]: string} = {
      'desktop': 'Desktop.Count.Metrics',
      'mobile': 'Mobile.Count.Metrics',
      'tablet': 'Tablet.Count.Metrics',
    }

    if (knownPlatformList[platform]){
      SendMetrics({name: "Activation." + knownPlatformList[platform], value: 1, userSession: user_session});
    }
  }, [render, user_session]);

  // GET Home regions list
  React.useEffect(() => {
    // serviceGroupNames to check
    const serviceGroupNamesCheck = [
      "MOATOUTCOMES",
      "MOATANALYTICS",
      "MOATPRO",
      "MOATREACH",
      "DATAAPPEND",
      "OPTIMIZATIONODC",
      "CONTEXTODC",
      "CXUNITY"
    ];

    // push to serviceGroupNames for local only
    // serviceGroupNames.push("MOATOUTCOMES", "UGBUGAM");
    // serviceGroupNames.push("UGBUGAM");

    // fake result for local only
    // const result = mockRegionsResponse;

    const regionIdString: string = dataCenterRegionId && dataCenterRegionId !== '' ? '&dataCenterRegion=' + dataCenterRegionId : '';
    const requestString: string = `/region${call2tenls ? 's':''}?${serviceGroupNames 
                                                                && serviceGroupNames.length > 0 ? 
                                                        'serviceType=' + serviceGroupNames.map(s => s.toUpperCase()) : ''}${dataCenterRegion ? ('&classicDataCenterRegionId=' + dataCenterRegion + regionIdString) : ''}`;

    fetchRegions(requestString, call2tenls)
    .then(async res => {
      if(res.status === 200) {
        const result = await res.json();
        let list: any[] = [];
        // @ts-ignore
        let regions: string[] = [...new Set([...result.items.flatMap(item => [...new Set(item.homeRegions.map(h => h.regionGroup))])])];
        if (regions.length > 1) {
          setRegionGroups(regions.map((item, k) => ({label: item, value: item})));
        }
        if(serviceGroupNames.length > 0) {
          serviceGroupNames.forEach((serviceGroupName =>{
            if (serviceGroupNamesCheck.includes(serviceGroupName)) {
              setFilterRegions(true)
            }
          }));
        }

        if (filterRegions) {
          if (serviceGroupNames.includes("CXUNITY")) {
            // eslint-disable-next-line
            result.items.filter((homeRegion: { homeRegions: any[]; id: any; regionName: any; }) => {
              if( homeRegion.id === "NORTH_AMERICA_REGION" || homeRegion.id === "EMEA_REGION" || homeRegion.id === "APAC_REGION") {
                return homeRegion
              }})
            .map((homeRegion: { homeRegions: any[]; id: any; regionName: any; }) => { 
              const subList = homeRegion.homeRegions.map(subRegion => {
                return({label: subRegion.regionName, value: {id: subRegion.id}, group: homeRegion.id, regionGroup: subRegion.regionGroup});
              });
              const dataRegion = {label: homeRegion.regionName,value: homeRegion.id, options: subList}
              list = list.concat(dataRegion);
              return homeRegion;
            });
          } else {
            // eslint-disable-next-line
            result.items.filter((homeRegion: { homeRegions: any[]; id: any; regionName: any; }) => {
              if(homeRegion.id === "NORTH_AMERICA_REGION") {
                return homeRegion
              }})
            .map((homeRegion: { homeRegions: any[]; id: any; regionName: any; }) => { 
              const subList = homeRegion.homeRegions.map(subRegion => {
                return({label: subRegion.regionName, value: {id: subRegion.id}, group: homeRegion.id, regionGroup: subRegion.regionGroup});
              });
              const dataRegion = {label: homeRegion.regionName,value: homeRegion.id, options: subList}
              list = list.concat(dataRegion);
              return homeRegion;
            });
          }
        } else {
          result.items.map((homeRegion: { homeRegions: any[]; id: any; regionName: any; }) => {
            const subList = homeRegion.homeRegions.map(subRegion => {
              return({label: subRegion.regionName, value: {id: subRegion.id}, group: homeRegion.id, regionGroup: subRegion.regionGroup});
            });
            const dataRegion = {label: homeRegion.regionName,value: homeRegion.id, options: subList}
            list = list.concat(dataRegion);
            return homeRegion;
          });
        }
        if (dataCenterRegion === 'DED-ONPREM' || dataCenterRegion === 'DED-COLO' || serviceGroupNames.includes('ORACLEDBATAZURE')) {
          // @ts-ignore
          dispatch({
            type: 'HOME_REGIONS_LIST',
            payload: "object",
            object: list
          });
          setHomeRegionList(list);
        } else {
          dispatch({
            type: 'HOME_REGIONS_LIST',
            payload: "object",
            object: list
          });
          setHomeRegionList(list);
        }
      }

      if(res.status >= 400) {
        SendMetrics({name:"ActivationRegion.Count.Metrics.Error", value:1, userSession:user_session});
        setApiError();
      }
      SendLogs({severity: 20000, message: `GET Home regions list successful, orderNumber: ${orderNumber}`, userSession:user_session});
      SendMetrics({name:"ActivationRegion.Count.Metrics", value:1, userSession:user_session});
    }).catch(error => {
      console.info(error);
      SendLogs({severity: 40000, message: `GET Home regions list error: ${error.message}, orderNumber: ${orderNumber}`, userSession:user_session});
      SendMetrics({name:"ActivationRegion.Count.Metrics.Fail", value:1, userSession:user_session});
      setApiError();
    });
  }, [render, dispatch, user_session, setApiError, dataCenterRegion, dataCenterRegionId, serviceGroupNames, setFilterRegions, filterRegions, acceptRegionRestrictions, token, featureFlags, call2tenls, orderNumber]);

  React.useEffect(() => {
    if (homeRegionList.length > 0 && regionGroup !== null) {
      let filteredHomeRegionListByRegionGroup = homeRegionList.map(item => {
        return {...item, options: item.options.filter((option: any) => option.regionGroup === regionGroup.value)};
      }).filter(row => row.options.length > 0);

      dispatch({
        type: 'HOME_REGIONS_LIST',
        payload: "object",
        object: filteredHomeRegionListByRegionGroup
      });
    }
  }, [regionGroup, dispatch, homeRegionList]);

  // GET banner
  React.useEffect(() => {
    const checkBanner = (bannerStartTime: number, bannerEndTime: number) => {
      const currentTime = new Date().getTime();
      if (currentTime >= bannerStartTime && currentTime <= bannerEndTime) {
        dispatch({
          type: "UPDATE_SHOW_BANNER",
          payload: "boolean",
          boolean: true
        });
      }
    };

    fetchBanner()
    .then(async res => {
      if(res.status === 200) {
          const result = await res.json();
          // second item in array for activation
          const banner = result.items[1];
          if (banner) {
            dispatch({
              type: "UPDATE_BANNER_CONTENT",
              payload: "object",
              object: banner
            });
          }
          checkBanner(Date.parse(banner.startTime), Date.parse(banner.endTime));
      }
      if(res.status >= 400) {
        SendMetrics({name:"ActivationBanner.Count.Metrics.Error", value:1, userSession:user_session});
      }
      SendLogs({severity: 20000, message: `GET banner list successful, orderNumber: ${orderNumber}`, userSession:user_session});
      SendMetrics({name:"ActivationBanner.Count.Metrics", value:1, userSession:user_session});
    }).catch(error => {
      console.log(error);
      SendLogs({severity: 40000, message: `GET banner list error: ${error.message}, orderNumber: ${orderNumber}`, userSession:user_session});
      SendMetrics({name:"ActivationBanner.Count.Metrics.Fail", value:1, userSession:user_session});
    })
  }, [render, user_session, dispatch, orderNumber]);

  // Validate preset values
  React.useEffect(() => {
    let keys = keysIn(touched)

    const presetValidation = async(e: { target: { value: string; name: any; }; }, action: string) => {
      let val = e.target.value.trim()
      const name = e.target.name;
      setTouched(touched => {
        return{...touched, ...{[name]: true}}
      });
      dispatch({type: `${action}`, payload: val});
    }

    if (firstName && firstName.length > 1 && keys.indexOf('firstName') < 0) {
      const fName: { target: { value: string; name: any; }} = { target: { name: 'firstName', value: firstName}};
      const setFname = async () => {
        await presetValidation(fName, 'UPDATE_FIRST_NAME');
      }
      setFname();
    }

    if (lastName && lastName.length > 1 && keys.indexOf('lastName') < 0) {
      const lName: { target: { value: string; name: any; }} = { target: { name: 'lastName', value: lastName}};
      const setLname = async () => {
        await presetValidation(lName, 'UPDATE_LAST_NAME');
      }
      setLname();
    }

    if (emailAddress && emailAddress.length > 1 && keys.indexOf('emailAddress') < 0) {
      const email: { target: { value: string; name: any; }} = { target: { name: 'emailAddress', value: emailAddress}};
      const setEmail = async () => {
        await presetValidation(email, 'UPDATE_EMAIL_ADDRESS');
      }
      setEmail();
    }

    if (tenancyName && tenancyName.length > 1 && keys.indexOf('tenancyName') < 0) {
      const tName: { target: { value: string; name: any; }} = { target: { name: 'tenancyName', value: tenancyName}};
      const setTenancyName = async () => {
        await presetValidation(tName, 'UPDATE_TENANCY_NAME');
      }
      setTenancyName();
    }

  },[firstName, lastName, emailAddress, tenancyName, dispatch, touched, setTouched]);

  const disableContinue = () => {
    if(showBanner){
      return true;
    }

    const errorCheck = () => {
      const eflags: string[] = [];
      Object.entries(error).forEach(key =>  {
        if ( key[0] in touched && isRequiredField(key[0]) && key[1].isValid){
          eflags.push("false");
        } else if(key[0] in touched) {
          eflags.push((!key[1].isValid).toString());
        }
      });
      const eflag = eflags.find((flag) => flag === "true") ? true : false;
      return eflag
    }

    const passCheck = () => {
      if (passwordError.passwordValid && passwordError.matchPassword) {
        return false;
      }
      return true;
    }

    const checkboxCheck = () => {
      return !acceptRegionRestrictions;
    }

    const flag: boolean = errorCheck() || passCheck() || checkboxCheck();

    return flag;
  }

  React.useEffect(() => {
    let statesList = [];
    if (_.get(address, 'country', '') !== '' && updateStateList) {
      fetchStateId(address.country)
        .then(async (res) => {
        if (res.status === 200) {
          statesList = await res.json();
          const statesFromApi = statesList != null
              && statesList.items.map((state: any) => ({ value: { id: state.id }, label: state.name }));
          dispatch({
            type: 'UPDATE_STATES_LIST',
            payload: 'object',
            object: statesFromApi
          });
        } else {
          dispatch({
            type: 'UPDATE_STATES_LIST',
            payload: 'object',
            object: null
          });
        }
      }).catch((e) => {
        console.log(e);
        dispatch({
          type: 'UPDATE_STATES_LIST',
          payload: 'object',
          object: null
        });
      });
      setUpdateStateList(false);
    }
  },[countryInfo, address.country, address, dispatch, updateStateList])

  const closeErrorModal = () => {
    dispatch({
      type:'UPDATE_APIERROR',
      payload: 'boolean',
      boolean: false
    });
  }


  const isRequiredField = (name: string) => {
    switch (name) {
      case 'address1':
        return _.get(countryInfo, 'addressFields.Address1[0]', '').startsWith('R');
      case 'address2':
        return _.get(countryInfo, 'addressFields.Address2[0]', '').startsWith('R');
      case 'address3':
        return _.get(countryInfo, 'addressFields.Address3[0]', '').startsWith('R');
      case 'address4':
        return _.get(countryInfo, 'addressFields.Address4[0]', '').startsWith('R');
      case 'city':
        return _.get(countryInfo, 'addressFields.City[0]', '').startsWith('R');
      case 'county':
        return _.get(countryInfo, 'addressFields.County[0]', '').startsWith('R');
      case 'state':
        return _.get(countryInfo, 'addressFields.State[0]', '').startsWith('R');
      case 'province':
        return _.get(countryInfo, 'addressFields.Province[0]', '').startsWith('R');
      case 'postalCode':
        return _.get(countryInfo, 'addressFields.PostalCode[0]', '').startsWith('R');
      case 'partyName':
        return customerTypePublicOffer === 'ORGANIZATION';
      case 'alternatePartyName':
        return ['JP','CN', 'TW', 'KR'].includes(address.country.toUpperCase()) && customerTypePublicOffer === 'ORGANIZATION';
      case 'alternateEnglishName':
        return ['JP','CN', 'TW', 'KR'].includes(address.country.toUpperCase());
      case 'customerPhoneNumber':
        return true;
      default:
        return false;
    }
  };

  // @ts-ignore
  return (
    <>
      <div className="upperContentContainer">
        {apiError &&
          <RedwoodModal
            onClose={() => closeErrorModal()}
            show={true}
            buttonName={"Close"}
            wrapperClass={"sticky"}
          >
            <Notifier type="error" message={addressValidationMessage !== '' ? addressValidationMessage : t('activationForm.error.gen', "Error")} position={addressValidationMessage !== '' ? 'createAzureError' : perfillError ? 'apiError' : 'createError'} />
          </RedwoodModal>
        }
        <form autoComplete="off">
          <div className="title6">{formText.label.newAccountInformation}</div>
          {publicOffer && <div className="row customer-type-select">
            <div className="col-md-12 col-xs-12">
              <label>
                <strong>Customer Type</strong>
              </label>
              <div className="row">
                <div key="personal" className="col-xs-3">
                  <label aria-label="customer-type">
                    <input type="radio" id="individual-customer-radio" name="customer-type" checked={customerTypePublicOffer === 'INDIVIDUAL'} value="INDIVIDUAL" onClick={(e) => { changeCustomerType(e); updatePartyNameEvent('individual') }} />
                    {' '}
                    Personal
                  </label>
                </div>
                <div key="business" className="col-xs-3">
                  <label aria-label="customer-type">
                    <input type="radio" id="business-customer-radio" name="customer-type" checked={customerTypePublicOffer === 'ORGANIZATION'} value="ORGANIZATION" onClick={(e) => { changeCustomerType(e); updatePartyNameEvent('organization') }} />
                    {' '}
                    Business
                  </label>
                </div>
              </div>
            </div>
          </div>}
          {publicOffer && <div className="row country-select">
            <div className="col-md-12 col-xs-12">
              <RedwoodSelect
                  placeholder={t('activationForm.placeholder.country', 'Country')}
                  label={t('activationForm.placeholder.country', 'Country')}
                  name="country"
                  value={countryObject}
                  data={countriesList()}
                  isPublicOffer={publicOffer}
                  required
                  error={restrictedCountry}
                  errorMsg={(
                      <Trans i18nKey="activationForm.error.restrictedCountry">
                        Free trials are currently not provided in this country.  If you are interested in learning more about Oracle Cloud Infrastructure (OCI), please click <a href="https://www.oracle.com/cloud/why-oci/" target="_blank" rel="noreferrer">here</a>.
                      </Trans>
                  )}
                  onChange={(e: any) => {
                    // @ts-ignore
                    const countryInfo = countries.countries[e.value];
                    if (isRestrictedCountry(e.value)) {
                      dispatch({
                        type: 'UPDATE_RESTRICTED_COUNTRY',
                        payload: 'boolean',
                        boolean: true
                      });
                      dispatch({
                        type: 'UPDATE_COUNTRY',
                        payload: "object",
                        object: {country: e.value, countryInfo, countryObject: e}
                      });
                      setTouched({"country": true});
                      setError(error => {
                        // eslint-disable-next-line
                        return {...error, ...{['country']: {isValid: false, value: e, errorMsg: ''}}}
                      });
                    } else {
                      dispatch({
                        type: 'RESET_PUBLIC_OFFER_FORM',
                        payload: ''
                      });
                      dispatch({
                        type: 'UPDATE_RESTRICTED_COUNTRY',
                        payload: 'boolean',
                        boolean: false
                      });
                      dispatch({
                        type: 'UPDATE_COUNTRY',
                        payload: "object",
                        object: {country: e.value, countryInfo, countryObject: e}
                      });
                      dispatch({ type: 'UPDATE_STATES_LIST', payload: 'object', object: null });
                      dispatch({ type: 'UPDATE_SELECTED_STATE', payload: 'object', object: null });
                      dispatch({ type: 'UPDATE_SELECTED_PROVINCE', payload: 'object', object: null });
                      setTouched({"country": true});
                      setError(error => {
                        // eslint-disable-next-line
                        return {...error, ...{['country']: {isValid: true, value: e, errorMsg: ''}}}
                      });
                      resetErrorState();
                      setUpdateStateList(true);
                    }
                  }}
                  onBlur={(e: {target: { value: string; name: any; }}) => {
                    setError(error => {
                      return {...error, ...{[e.target.name]: {isValid: true, value: e, errorMsg: ''}}}
                    });
                  }}
              />
            </div>
          </div>}
          {publicOffer && customerTypePublicOffer === 'ORGANIZATION' && <>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <RedwoodInputText
                    type="text"
                    placeholder={t('personalInfo.organizationPlaceholder')}
                    isPublicOffer={publicOffer}
                    label={t('personalInfo.organization', 'Legal Company Name')}
                    name="partyName"
                    value={partyName}
                    required
                    valid={isRequiredField('partyName') && error.partyName.isValid}
                    error={isRequiredField('partyName') && error.partyName.errorMsg}
                    onChange={(e: {target: any}) => onChangeHandler(e, 'UPDATE_PARTY_NAME', e.target.value)}
                    onBlur={(e: {target: { value: string; name: any; }}) => onBlurHandler(e, regExp.organization, 'UPDATE_PARTY_NAME')}
                />
              </div>
            </div>
            {isRequiredField('alternatePartyName') &&
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <RedwoodInputText
                    type="text"
                    label={t('activationForm.alternatePartyName', 'Alternate English Legal Company Name')}
                    placeholder={t('signup.altEngCompanyNamePlaceholder', 'Enter alternate english company name')}
                    isPublicOffer={publicOffer}
                    name="alternatePartyName"
                    value={alternatePartyName}
                    required={isRequiredField('alternatePartyName')}
                    valid={!isRequiredField('alternatePartyName') || error.alternatePartyName.isValid}
                    error={isRequiredField('alternatePartyName') && error.alternatePartyName.errorMsg}
                    onChange={(e: {target: any}) => onChangeHandler(e, 'UPDATE_ALT_PARTY_NAME', e.target.value)}
                    onBlur={(e: {target: { value: string; name: any; }}) => onBlurHandler(e, regExp.altEnglishCompanyName, 'UPDATE_ALT_PARTY_NAME')}
                />
              </div>
            </div>}
          </>}
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <RedwoodInputText
                  type="text"
                  placeholder={formText.label.firstName}
                  isPublicOffer={publicOffer}
                  label={formText.label.firstName}
                  name="firstName"
                  value={firstName? firstName : ""}
                  error={error.firstName.errorMsg}
                  valid={error.firstName.isValid}
                  required
                  onChange={(e: { target: any; }) => onChangeHandler(e, 'UPDATE_FIRST_NAME', e.target.value)}
                  onBlur={(e: { target: { value: string; name: any; }; }) => {onBlurHandler(e, regExp.personName, 'UPDATE_FIRST_NAME');}}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <RedwoodInputText
                  type="text"
                  placeholder={formText.label.lastName}
                  isPublicOffer={publicOffer}
                  label={formText.label.lastName}
                  name="lastName"
                  value={lastName? lastName : ""}
                  error={error.lastName.errorMsg}
                  valid={error.lastName.isValid}
                  required
                  onChange={(e: { target: any; }) => onChangeHandler(e, 'UPDATE_LAST_NAME', e.target.value)}
                  onBlur={(e: { target: { value: string; name: any; }; }) => onBlurHandler(e, regExp.personName, 'UPDATE_LAST_NAME')}
              />
            </div>
          </div>
          {publicOffer && <>
          {isRequiredField("alternateEnglishName") && <div className="row alt-name">
              <div className="col-md-12 col-xs-12">
                <RedwoodInputText
                    type="text"
                    label={formText.label.altEnglishName}
                    name="altEnglishName"
                    value={alternateEnglishName}
                    placeholder={formText.label.altNamePlaceholder}
                    required={isRequiredField("alternateEnglishName")}
                    valid={!isRequiredField("alternateEnglishName") || error.altEnglishName.isValid}
                    error={isRequiredField("alternateEnglishName") && error.altEnglishName.errorMsg}
                    isPublicOffer={publicOffer}
                    onChange={(e: {target: any; }) => onChangeHandler(e, 'UPDATE_ALT_ENGLISH_NAME', e.target.value)}
                    onBlur={(e: {target: { value: string; name: any; }; }) => onBlurHandler(e, regExp.altEnglishName, 'UPDATE_ALT_ENGLISH_NAME', formText.validation.altEnglishName)}
                />
              </div>
            </div>}
            <div className="row phone">
              <div className="col-md-12 col-xs-12">
                <PhoneInput
                    inputProps={{
                      name: 'customerPhoneNumber',
                      required: true
                    }}
                    placeholder=""
                    areaCodes={{ ci: ['27'] }}
                    enableTerritories
                    isValid={error.customerPhoneNumber.isValid}
                    country={_.lowerCase(address.country)}
                    value={customerPhoneNumber}
                    onChange={(phone, data) => {
                      dispatch({
                        type: 'UPDATE_PHONE',
                        payload: phone.trim()
                      });
                    }}
                    onBlur={(e: { target: { value: string; name: any; }; }) => onBlurHandler(e, '-1', 'UPDATE_PHONE')}
                />
              </div>
            </div>
            {_.get(countryInfo, 'addressFields.Address1', '') !== '' &&
            <div className="row address1">
              <div className="col-md-12 col-xs-12">
                <RedwoodInputText
                    type="text"
                    label={`${_.get(countryInfo, 'addressFields.Address1[1]', '') !== '' ? 
                        t(`personalInfo.${_.get(countryInfo, 'addressFields.Address1[1]')}`) :
                        t('personalInfo.address1', 'Address Line 1')}`}
                    name="address1"
                    value={address.address1}
                    placeholder={t('personalInfo.addressPlaceholder')}
                    isPublicOffer={publicOffer}
                    required={_.get(countryInfo, 'addressFields.Address1[0]', '') === 'R'}
                    valid={error.address1.isValid}
                    error={error.address1.errorMsg}
                    onChange={(e: {target: any}) => onChangeHandler(e, 'UPDATE_ADDRESS1', e.target.value)}
                    onBlur={(e: {target: { value: string; name: any; }}) => { onBlurHandler(e, '^.{1,240}$', 'UPDATE_ADDRESS1')}}
                />
              </div>
            </div>}
            {_.get(countryInfo, 'addressFields.Address2', '') !== '' &&
            <div className="row address2">
                <div className="col-md-12 col-xs-12">
                  <RedwoodInputText
                      type="text"
                      label={`${_.get(countryInfo, 'addressFields.Address2[1]', '') !== '' ?
                          t(`personalInfo.${_.get(countryInfo, 'addressFields.Address2[1]')}`) :
                          t('personalInfo.address2', 'Address Line 2')}`}
                      name="address2"
                      value={address.address2}
                      placeholder={t('personalInfo.addressPlaceholder')}
                      isPublicOffer={publicOffer}
                      required={isRequiredField('address2')}
                      valid={!isRequiredField('address2') || error.address2.isValid}
                      error={isRequiredField('address2') && error.address2.errorMsg}
                      onChange={(e: {target: any}) => onChangeHandler(e, 'UPDATE_ADDRESS2', e.target.value)}
                      onBlur={(e: {target: { value: string; name: any; }}) => onBlurHandler(e, isRequiredField('address2') ? '^.{1,240}$' : '-1', 'UPDATE_ADDRESS2')}
                  />
                </div>
            </div>}
            {_.get(countryInfo, 'addressFields.Address3', '') !== '' &&
            <div className="row address3">
              <div className="col-md-12 col-xs-12">
                <RedwoodInputText
                    type="text"
                    label={`${_.get(countryInfo, 'addressFields.Address3[1]', '') !== '' ?
                        t(`personalInfo.${_.get(countryInfo, 'addressFields.Address3[1]')}`) :
                        t('personalInfo.address3', 'Address Line 3')}`}
                    name="address3"
                    value={address.address3}
                    placeholder={t('personalInfo.addressPlaceholder')}
                    isPublicOffer={publicOffer}
                    required={isRequiredField('address3')}
                    valid={!isRequiredField('address3') || error.address3.isValid}
                    error={isRequiredField('address3') && error.address3.errorMsg}
                    onChange={(e: {target: any}) => onChangeHandler(e, 'UPDATE_ADDRESS3', e.target.value)}
                    onBlur={(e: {target: { value: string; name: any; }}) => onBlurHandler(e, isRequiredField('address3') ? '^.{1,240}$' : '-1', 'UPDATE_ADDRESS3')}
                />
              </div>
            </div>}
            {_.get(countryInfo, 'addressFields.Address4', '') !== '' &&
            <div className="row address4">
              <div className="col-md-12 col-xs-12">
                <RedwoodInputText
                    type="text"
                    label={`${_.get(countryInfo, 'addressFields.Address4[1]', '') !== '' ?
                        t(`personalInfo.${_.get(countryInfo, 'addressFields.Address4[1]')}`) :
                        t('personalInfo.address4', 'Address Line 4')}`}
                    name="address4"
                    value={address.address4}
                    placeholder={t('personalInfo.addressPlaceholder')}
                    isPublicOffer={publicOffer}
                    required={isRequiredField('address4')}
                    valid={!isRequiredField('address4') || error.address4.isValid}
                    error={isRequiredField('address4') && error.address4.errorMsg}
                    onChange={(e: {target: any}) => onChangeHandler(e, 'UPDATE_ADDRESS4', e.target.value)}
                    onBlur={(e: {target: { value: string; name: any; }}) => onBlurHandler(e, isRequiredField('address4') ? '^.{1,240}$' : '-1', 'UPDATE_ADDRESS4')}
                />
              </div>
            </div>}
            {_.get(countryInfo, 'addressFields.City', '') !== '' &&
            <div className="row city">
              <div className="col-md-12 col-xs-12">
                <RedwoodInputText
                    type="text"
                    label={`${address.country === 'CR' ?
                        'Canton' :
                        t('personalInfo.city', 'City')}`}
                    name="city"
                    value={address.city}
                    placeholder={t('personalInfo.cityPlaceholder')}
                    isPublicOffer={publicOffer}
                    required={isRequiredField('city')}
                    valid={!isRequiredField('city') || error.city.isValid}
                    error={isRequiredField('city') && error.city.errorMsg}
                    onChange={(e: {target: any}) => onChangeHandler(e, 'UPDATE_CITY', e.target.value)}
                    onBlur={(e: {target: { value: string; name: any; }}) => onBlurHandler(e, '^.{1,60}$', 'UPDATE_CITY')}
                />
              </div>
            </div>}
            <div className="row">
              {_.get(countryInfo, 'addressFields.State', '') !== '' &&
              <div className="col-md-6 col-xs-12 state">
                {_.isArray(statesList) &&
                <RedwoodSelect
                    type="text"
                    data={statesList}
                    value={selectedState}
                    placeholder={t('personalInfo.statePlaceholder')}
                    isPublicOffer={publicOffer}
                    label={`${
                        _.get(countryInfo, 'addressFields.State[1]', '') !== ''
                            ? t(`personalInfo.${_.get(countryInfo, 'addressFields.State[1]')}`)
                            : t('personalInfo.state', 'State')
                    }`}
                    name="state"
                    required={isRequiredField('state')}
                    valid={!isRequiredField('state') || error.state.isValid}
                    error={isRequiredField('state') && error.state.errorMsg}
                    onChange={(e: any) => {
                      const stateValue: string = countryObject.value.toUpperCase() === 'BR' ? _.split(e.value.id, '-')[1].trim() : e.label;
                      onChangeHandler({target: { name: "state" }}, 'UPDATE_STATE', stateValue);
                      dispatch({type: 'UPDATE_SELECTED_STATE', payload: 'object', object: e });
                    }}
                />}
                {!_.isArray(statesList) &&
                <RedwoodInputText
                    type="text"
                    label={`${
                        _.get(countryInfo, 'addressFields.State[1]', '') !== ''
                            ? t(`personalInfo.${_.get(countryInfo, 'addressFields.State[1]')}`)
                            : t('personalInfo.state', 'State')
                    }`}
                    name="state"
                    value={address.state}
                    placeholder={t('personalInfo.statePlaceholder')}
                    isPublicOffer={publicOffer}
                    required={isRequiredField('state')}
                    valid={!isRequiredField('state') || error.state.isValid}
                    error={isRequiredField('state') && error.state.errorMsg}
                    onChange={(e: {target: any}) => onChangeHandler(e, 'UPDATE_STATE', e.target.value)}
                    onBlur={(e: {target: { value: string; name: any; }}) => onBlurHandler(e, '^.{1,60}$', 'UPDATE_STATE')}
                />
                }
              </div>}
              {_.get(countryInfo, 'addressFields.Province', '') !== '' &&
              <div className="col-md-6 col-xs-12 province">
                {_.isArray(statesList) &&
                    <RedwoodSelect
                        type="text"
                        label={`${
                            _.get(countryInfo, 'addressFields.Province[1]', '') !== ''
                                ? t(`personalInfo.${_.get(countryInfo, 'addressFields.Province[1]')}`)
                                : t('personalInfo.province', 'Province')
                        }`}
                        name="province"
                        value={selectedProvince}
                        data={statesList}
                        placeholder={t('personalInfo.provincePlaceholder')}
                        isPublicOffer={publicOffer}
                        required={isRequiredField('province')}
                        valid={!isRequiredField('province') || error.province.isValid}
                        error={isRequiredField('province') && error.province.errorMsg}
                        onChange={(e: any) => {
                          onChangeHandler({target: { name: "province"}}, 'UPDATE_PROVINCE', e.label);
                          dispatch({type: 'UPDATE_SELECTED_PROVINCE', payload: 'object', object: e });
                        }}
                    />
                }
                {!_.isArray(statesList) &&
                <RedwoodInputText
                    type="text"
                    label={`${
                        _.get(countryInfo, 'addressFields.Province[1]', '') !== ''
                            ? t(`personalInfo.${_.get(countryInfo, 'addressFields.Province[1]')}`)
                            : t('personalInfo.province', 'Province')
                    }`}
                    name="province"
                    value={address.province}
                    placeholder={t('personalInfo.provincePlaceholder')}
                    isPublicOffer={publicOffer}
                    required={isRequiredField('province')}
                    valid={!isRequiredField('province') || error.province.isValid}
                    error={isRequiredField('province') && error.province.errorMsg}
                    onChange={(e: {target: any}) => onChangeHandler(e, 'UPDATE_PROVINCE', e.target.value)}
                    onBlur={(e: {target: { value: string; name: any; }}) => onBlurHandler(e, isRequiredField('province') ? '^.{1,60}$' : '-1', 'UPDATE_PROVINCE')}
                />
                }
              </div>}
              {_.get(countryInfo, 'addressFields.PostalCode', '') !== '' &&
              <div className="col-md-6 col-xs-12 zipcode">
                <RedwoodInputText
                    type="text"
                    placeholder={t('personalInfo.zipPlaceholder')}
                    isPublicOffer={publicOffer}
                    label={t('personalInfo.zip', 'Zip/Postal Code')}
                    name="postalCode"
                    value={address.postalCode}
                    required={isRequiredField('postalCode')}
                    valid={!isRequiredField('postalCode') || error.postalCode.isValid}
                    error={isRequiredField('postalCode') && error.postalCode.errorMsg}
                    onChange={(e: {target: any}) => onChangeHandler(e, 'UPDATE_POSTAL_CODE', e.target.value)}
                    onBlur={(e: {target: { value: string; name: any; }}) => onBlurHandler(e, _.get(countryInfo, 'addressFields.PostalCode[1]', '^[0-9]{5}$'), 'UPDATE_POSTAL_CODE', _.get(countryInfo, 'addressFields.PostalCode[2]', ''))}
                />
              </div>}
              {_.get(countryInfo, 'addressFields.County', '') !== '' &&
              <div className="col-md-12 col-xs-12 county">
                <RedwoodInputText
                    type="text"
                    label={getCountyLabel()}
                    name="county"
                    value={address.county}
                    placeholder={t('personalInfo.countyPlaceholder')}
                    isPublicOffer={publicOffer}
                    required={isRequiredField('county')}
                    valid={!isRequiredField('county') || error.county.isValid}
                    error={isRequiredField('county') && error.county.errorMsg}
                    onChange={(e: {target: any}) => onChangeHandler(e, 'UPDATE_COUNTY', e.target.value)}
                    onBlur={(e: {target: { value: string; name: any; }}) => onBlurHandler(e, isRequiredField('county') ? '^.{1,60}$' : '-1', 'UPDATE_COUNTY')}
                />
              </div>}
            </div>
          </>}
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <RedwoodInputText
                type="text"
                placeholder={formText.label.email}
                label={formText.label.email}
                name="emailAddress"
                value={emailAddress}
                valid={error.emailAddress.isValid}
                error={error.emailAddress.errorMsg}
                required
                isPublicOffer={publicOffer}
                onChange={(e: { target: any; }) => onChangeHandler(e, 'UPDATE_EMAIL_ADDRESS', e.target.value)}
                onBlur={(e: { target: { value: string; name: any; }; }) => onBlurHandler(e, regExp.email, 'UPDATE_EMAIL_ADDRESS')}
              />
            </div>
          </div>
          <div className="row">
            <PasswordComponent updateTouched={updateTouched} updatePasswordErrorFlag={passwordErrorFlag} passwordError={passwordError}/>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <RedwoodInputText
                type="text"
                placeholder={formText.label.tenancyName}
                label={formText.label.tenancyName}
                name="tenancyName"
                value={tenancyName}
                error={error.tenancyName.errorMsg}
                valid={error.tenancyName.isValid}
                info={true}
                infoMsg={formText.info.tenancyName}
                required
                onChange={(e: { target: any; }) => onChangeHandler(e, 'UPDATE_TENANCY_NAME', e.target.value)}
                onBlur={(e: { target: { value: string; name: any; }; }) => onBlurTenancyNameHandler(e, regExp.tenancy, 'UPDATE_TENANCY_NAME')}
              />
            </div>
          </div>
          {!themeOn && regionGroups && regionGroups.length > 1  &&
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <RedwoodSelect
                      placeholder={formText.label.regionGroup}
                      label={formText.label.regionGroup}
                      name="regionGroup"
                      data={regionGroups}
                      value={regionGroup}
                      required
                      isPublicOffer={publicOffer}
                      onChange={(e: any) => {
                        setTouched({...touched, "regionGroup": true})
                        if (homeRegion !== null) {
                          dispatch({
                            type: 'UPDATE_HOME_REGION',
                            payload: "object",
                            object: null
                          })
                          setTouched({...touched, "homeRegion": errorState})
                        }
                        dispatch({
                          type: 'UPDATE_REGION_GROUP',
                          payload: "object",
                          object: e
                        })
                      }}
                  />
                </div>
              </div>
          }
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <RedwoodSelect
                placeholder={formText.label.homeRegion}
                label={formText.label.homeRegion}
                name="homeRegion"
                required
                value={homeRegion}
                data={homeRegions}
                info={themeOn !== true}
                isPublicOffer={publicOffer}
                infoMsg={formText.info.homeRegion}
                onChange={(e: any) => {
                  setTouched({...touched, "homeRegion": true})
                  if (acceptRegionRestrictions) setAcceptRegionRestrictions(false);
                  dispatch({
                    type:`UPDATE_HOME_REGION`,
                    payload: "object",
                    object: e
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <RedwoodCheckbox
                  checked={acceptRegionRestrictions}
                  onChange={() => setAcceptRegionRestrictions(!acceptRegionRestrictions)}>
                {checkboxTextRegionGroups(regionGroup?.value)}
              </RedwoodCheckbox>
            </div>
          </div>
          <></>
          {isInternalCustomerType && themeOn !== true &&
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <RedwoodSelect
                placeholder={formText.label.tenancyType}
                label={formText.label.tenancyType}
                name="tenancyType"
                value={domainType}
                data={tenancyTypes}
                required
                isPublicOffer={publicOffer}
                info={true}
                infoMsg={formText.info.tenancyType}
                onChange={(e: any) => {
                  setTenancyNameChanged(true);
                  setTouched({...touched, "domainType": true})
                  dispatch({
                    type:`UPDATE_DOMAIN_TYPE`,
                    payload: "object",
                    object: e
                  });
                }}
              />
            </div>
          </div>
          }
        </form>
      </div>
      <TermsOfUseComponent status={disableContinue()}/>
    </>
  );
}

const ActivationFormContainer: React.FC<{}> = () => {
  return (
    <div className="contentContainer">
      <FormDisplay />
    </div>
  );
};

export default withTranslation()(ActivationFormContainer);
