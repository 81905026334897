import * as React from 'react';
import TermsOfUseBody from "./Helper"
import TermsOfUseLinks from "../../Constants/legalLinks";
import './TermsOfUseComponent.css';
import RedwoodCheckbox from "../../common/Checkbox/RedwoodCheckbox"
import Button from "../../common/Button/Button"
import {Trans, useTranslation} from "react-i18next";
import SendAction from '../../CourierService/SendAction';
import {useFormState, useFormDispatch} from "../main/ActivationForm/Context/ActivationFormContext"
import SendMetrics from '../../CourierService/SendMetrics';
import {themeOn} from "../../config/apiConfig";

const TermsOfUseComponent = (props: { status: boolean; }) => {
  const dispatch = useFormDispatch()
  const {
    user_session,
    country,
    serviceGroupNames
  } = useFormState()

  const [checked, setChecked] = React.useState(false);
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const {t} = useTranslation();
  const doClick = () => {
    SendAction({action:'termsOfUse_continue_button', value: 'clicked', userSession:user_session});
    if(serviceGroupNames.length > 0) {
      serviceGroupNames.forEach((serviceGroupName =>{
        SendMetrics({name:`CreateNewTenancy.${serviceGroupName}.Button.Click.Count`, value:1, userSession:user_session})
      }));
    }
    SendMetrics({name:"CreateNewTenancy.Button.Click.Count", value:1, userSession:user_session});
    dispatch({
      type: "SUBMIT_CREATE_TENANCY_INFO",
      payload: "boolean",
      boolean: true,
    })
  }

  const setContinueButton = () => {
    let flag = true;
    if(country === "RU"){
      (checked === true && checked1 === true) ? flag = props.status : flag = true;
    } else if (country === "KR" ){
      (checked === true && checked1 === true && checked2 === true && checked3 === true) ? flag = props.status : flag = true;
    } else {
      flag = props.status
    }
    return flag;
  }

  return (
    <div className="termsComponent" data-testid="termsComponent">
      { !themeOn
        && <div className="title6">
            {t('termsOfUse.termsOfUseLabel', "Terms Of Use" )}
          </div>
      }
      <div className="body2 terms">
        {country === "RU" ? 
          <div className="margin-top-10">
            <p>
            <Trans i18nKey="termsOfUse.ruPrivacyPolice">Use of Oracle's web site is subject to the <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.termsOfUseLink}> Oracle.com Terms of Use </a> 
            and <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.privacyLink}> Oracle Privacy Policy</a></Trans>.
            </p>
            <RedwoodCheckbox checked={checked} onChange={() => setChecked(!checked)}> {t('termsOfUse.understand', 'I understand and agree to the above')} </RedwoodCheckbox>
            <p>
            <Trans i18nKey="termsOfUse.ruTransferInfo">Oracle may transfer your personal information collected in connection with your registration on this website to 
              its  <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.affiliatesGloballyLink}> affiliates globally </a>and to 
              <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.thirdPartyLink}> third party entities </a> that provide services to 
              Oracle, including to affiliates and entities located in countries which are not considered to have an adequate level of data protection.</Trans>
            </p>
            <RedwoodCheckbox checked={checked1} onChange={() => setChecked1(!checked1)}> {t('termsOfUse.understand', 'I understand and agree to the above')} </RedwoodCheckbox>
          </div>
        : <>{country === "JP" ? 
            <>
              <p>
              <Trans i18nKey="termsOfUse.jpTerms">By clicking on the "Continue" button below, you understand and agree that the use of Oracle's web site is subject to the 
                <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.termsOfUseLink}> Oracle.com Terms of Use</a> and 
                <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.japanPrivacyLink}> Oracle Japan Privacy Statement</a>, 
                including the fact that Oracle may transfer your personal information collected in connection with your registration on this website to its 
                affiliates globally and to third party entities that provide services to Oracle.</Trans>
              </p>
            </>
          : <>{country === "PH" ? 
              <>
                <p>
                <Trans i18nKey="termsOfUse.phUnderstand">You understand and agree that use of Oracle's web site is subject to the <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.termsOfUseLink}>Oracle.com Terms of Use</a> and 
                  <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.japanPrivacyLink}>Oracle Privacy Statement</a></Trans>.
                </p>
                <p>
                <Trans i18nKey="termsOfUse.phPersonalInfo">You understand and agree that Oracle may transfer your personal information collected in connection with your registration on this website to its 
                  <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.affiliatesGloballyLink}> affiliates globally </a> and to 
                  <a target="_blank" rel="noopener noreferrer" href={TermsOfUseLinks.thirdPartyLink}> third party entities </a> that provide services to Oracle, 
                  including to affiliates and entities located in countries which are not considered by Philippine law to have an adequate level of data protection.</Trans>
                </p>
              </>
            : <>{country === "KR" ?
            <>
              <RedwoodCheckbox checked={checked} onChange={() => setChecked(!checked)}> 
                {t('termsOfUse.krTerm1')}
                <p>{t('termsOfUse.krTerm1Sub1')}</p>
                <p>{t('termsOfUse.krTerm1Sub2')}</p>
                <p>{t('termsOfUse.krTerm1Sub3')}</p>
                <p><Trans i18nKey="termsOfUse.krTerm1Sub4">I understand that additional details regarding Oracle's collection and use of my personal information are available in the <a href="https://www.oracle.com/us/legal/privacy/privacy-policy/index.html" target="_blank" rel="noopener noreferrer">Oracle Privacy Policy</a></Trans>.</p>
              </RedwoodCheckbox>
              <RedwoodCheckbox checked={checked1} onChange={() => setChecked1(!checked1)}> 
                {t('termsOfUse.krTerm2')}
                <p><Trans i18nKey="termsOfUse.krTerm2Sub1">A list of corporate affiliates is available <a href="https://www.oracle.com/us/corporate/contact/global-070511.html" target="_blank" rel="noopener noreferrer">here</a></Trans>.</p>
              </RedwoodCheckbox>
              <RedwoodCheckbox checked={checked2} onChange={() => setChecked2(!checked2)}> 
                {t('termsOfUse.krTerm3')}
                <p><Trans i18nKey="termsOfUse.krTerm3Sub1">A list of these countries is available <a href="https://www.oracle.com/us/corporate/contact/global-070511.html" target="_blank" rel="noopener noreferrer">here</a></Trans>.</p>
              </RedwoodCheckbox>
              <RedwoodCheckbox checked={checked3} onChange={() => setChecked3(!checked3)}>
                {t('termsOfUse.krTerm4')}
                <p><Trans i18nKey="termsOfUse.krTerm4Sub1">A list of these delegates and its delegated work scope is available <a href="https://www.oracle.com/corporate/contact/suppliers.html" target="_blank" rel="noopener noreferrer">here</a></Trans>.</p>
                <p>{t('termsOfUse.krFinalTerms')}</p>
              </RedwoodCheckbox>
            </>
            : <>{!themeOn ? <TermsOfUseBody country={country} termsOfUseLink={TermsOfUseLinks.termsOfUseLink} privacyPolicyLink={TermsOfUseLinks.privacyLink}/> : null } </>}
          </>}
          </>}
        </>} 
      </div>
        <Button type="solid" disabled={setContinueButton()} onClick={() => doClick()} text={themeOn ? t('activationForm.action.createAccount', "Create Account") : t('activationForm.action.create', "Create Tenancy")} />
    </div>
  )
}

export default TermsOfUseComponent;
