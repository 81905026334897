import * as React from "react";
import './SidebarInfoBlock.css';

type InfoBlockProps = {
  wrapperClass: string
}

const SidebarInfoBlock: React.FC<InfoBlockProps> = ({wrapperClass, children}) => {
  return (
    <div className={wrapperClass} data-testid="sidebar-info">
      {children}
    </div>
  );
};

export default SidebarInfoBlock;
