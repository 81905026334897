import React from 'react';
import './App.css';
import '../src/common/flexboxgrid.css';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import ActivationPage from './ActivationPage';

function App() {
  return (
    <Router>
      <Route exact path="/" component={ActivationPage} />
    </Router>
  );
}

export default App;
