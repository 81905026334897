import * as React from "react";
import "./RedwoodCheckbox.css";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var RedwoodCheckbox = function RedwoodCheckbox(props) {
  var children = props.children,
      checked = props.checked,
      rest = _objectWithoutProperties(props, ["children", "checked"]);

  return React.createElement(
    React.Fragment,
    null,
    React.createElement(
      "label",
      { className: "rw-checkbox" },
      React.createElement("input", _extends({
        type: "checkbox",
        checked: checked ? "checked" : ""
      }, rest)),
      React.createElement("div", { className: "checkmark" }),
      React.createElement(
        "span",
        null,
        children
      )
    )
  );
};

export default RedwoodCheckbox;