import * as React from 'react';
import './NonPendingRoom.css';
import {useTranslation} from "react-i18next";
import RedwoodModal from '../../common/Modal/RedwoodModal';
import { useFormState } from '../main/ActivationForm/Context/ActivationFormContext';
import Button from '../../common/Button/Button';
import OrderLifeCycleState from '../../Constants/orderLifeCycleState';

const NonPendingRoom = () => {
    const { processing, showThankYou, showBanner, nonAuth, nonPending, singleOrderState, tokenHasExpired, tokenLowerCase, fawError } = useFormState();
    const show: boolean = (!processing && !showThankYou && !showBanner && !nonAuth && (nonPending || tokenHasExpired || tokenLowerCase || fawError));

    const {t} = useTranslation();
    const doClick = () => {
        window.open('https://www.oracle.com/support/contact.html','_blank');
    }

    const [message, setMessage] = React.useState<string>("");

    React.useEffect(() => {
        if (tokenHasExpired) {
            setMessage(t('nonPending.messageExpired', "The order you are trying to complete has expired. To rebook the order, contact your Oracle sales representative."));
        } else if (tokenLowerCase) {
            setMessage(t('nonPending.lowerCase', "It appears you've arrived using an invalid URL. This is commonly caused when email systems manipulate email links.  Please contact support to request a valid URL."));
        } else if (fawError) {
            setMessage(t('nonPending.fawErrorDetails', "This subscription requires that your tenancy have a Universal IaaS and PaaS subscription already enabled. Please select the \"Add to existing cloud account\" option instead and activate into a tenancy that meets that criteria."));
        } else {
            switch (singleOrderState) {
                case OrderLifeCycleState.creating:
                    setMessage(t('nonPending.messageHold', "The order cannot be activated at this time since it is still being processed. Please try again later."));
                    break;

                case OrderLifeCycleState.hold:
                    setMessage(t('nonPending.messageHold', "The order cannot be activated at this time since it is still being processed. Please try again later."));
                    break;

                case OrderLifeCycleState.active:
                    setMessage(t('nonPending.messageActive', "The order has already been activated."));
                    break;

                case OrderLifeCycleState.activing:
                    setMessage(t('nonPending.messageActiving', "The order is currently being activated."));
                    break;

                case "ACTIVATING":
                    setMessage(t('nonPending.messageActiving', "The order is currently being activated."));
                    break;

                case OrderLifeCycleState.deleted:
                    setMessage(t('nonPending.messageDeleted', "The order is no longer valid and cannot be activated."));
                    break;

                default:
                    setMessage(t('nonPending.message', "There was a problem activating your order."));
                    break;
            }
        }
    },[singleOrderState, t, setMessage, tokenHasExpired, tokenLowerCase, fawError]);

    return (
        <>
        {show &&
            <div className={"ty-wrapper"} data-testid="nonpending-container">
                <RedwoodModal show={show} title={t('nonPending.title', "Cloud Infrastructure")} fullContainer={true}>
                    <hr className="thankyou" />
                    <p>{message}</p>
                    <Button style={{marginTop:0}} type="solid" onClick={() => doClick()} text={t('nonPending.btnTxt', "Contact Customer Support")} />
                </RedwoodModal>
            </div>
        }
        </>
    )
}

export default NonPendingRoom;
