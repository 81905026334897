import * as React from "react";
import { useFormState } from "../main/ActivationForm/Context/ActivationFormContext";
import {themeOn} from "../../config/apiConfig";
import SidebarInfoBlockContent from "./SidebarInfoBlockContent";
import {Trans, useTranslation} from "react-i18next";

const SidebarCloudAccountContent: React.FC = () => {
    const { processing, showThankYou, nonAuth, loading, loadingFeatureFlags, showBanner, nonPending, tokenHasExpired, fawError, companyName } = useFormState();
    const { t } = useTranslation();
    const getCloudAccountContent = () => {
        if (themeOn) {
            return <Trans i18nKey="infoBlock.contentOneGeneric">When you sign up for {{companyName}}, you get a cloud account and an {{companyName}} Infrastructure tenancy. {{companyName}} assigns the same names to the cloud account and the tenancy.</Trans>;
        }
        return t('infoBlock.contentOne', "When you sign up for Oracle Cloud, you get a cloud account and an Oracle Cloud Infrastructure tenancy. Oracle assigns the same name to the cloud account and the tenancy.");
    };

    return (
        <>
            {!processing && !showThankYou && !nonAuth && !loading && !loadingFeatureFlags && !showBanner && !nonPending && !tokenHasExpired && !fawError &&
                <SidebarInfoBlockContent
                    title={t('infoBlock.titleOne', "What is a Cloud Account?")}
                    content={ getCloudAccountContent() }
                />
            }
        </>
    );
};

export default SidebarCloudAccountContent;
