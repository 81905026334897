/* eslint-disable camelcase */
export const getLocaleLanguage = (locale: string) => {
    locale = locale.toLowerCase();
    const map = new Map([
        ['ar', 'ar-XB'],
        ['en-xa', 'en-XA'],
        ['en-xc', 'en-XC'],
        ['fr-ca', 'fr-CA'],
        ['pt-pt', 'pt-PT'],
        ['sr-latn', 'sr-Latn'],
        ['zh-hans', 'zh-Hans'],
        ['zh-hant', 'zh-Hant'],
        ['zh-cn', 'zh-Hans'],
        ['zh-sg', 'zh-Hans'],
        ['zh-hk', 'zh-Hant'],
        ['zh-tw', 'zh-Hant'],
        ['zh-mo', 'zh-Hant'],
        ['zh-hans-cn', 'zh-Hans'],
        ['zh-hans-sg', 'zh-Hans'],
        ['zh-hant-hk', 'zh-Hant'],
        ['zh-hant-tw', 'zh-Hant'],
        ['zh-hant-mo', 'zh-Hant'],
        ['zh', 'zh-Hans'],
        ['nb', 'no'],
        ['nn', 'no'],
        ['id', 'ms'],
    ]);

    if (map.has(locale)) {
        return map.get(locale);
    }
    if (locale.length > 2 && locale.substring(0, 3) === 'zh-') {
        return 'zh-Hans';
    }
    if (map.has(locale.substring(0, 2))) {
        return map.get(locale.substring(0, 2));
    }

    return locale.substring(0, 2);
};

export const isRestrictedCountry = (country: string) => ['AF', 'BI', 'TD', 'KM', 'CD', 'GQ', 'HT', 'LY', 'MM', 'NI', 'SO', 'SS', 'SD', 'TJ', 'TM', 'YE'].includes(country);